import React from "react";
import {
  Table,
  Spin,
  Popconfirm,
  Button,
  message,
  notification,
  Modal,
  Input,
} from "antd";
import sualtap from "./../../../const/api";
import { SmileOutlined } from "@ant-design/icons";
import { toggleLoading } from "./../../../redux/actions";
import { connect } from "react-redux";

const { Search } = Input;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Sentence - es",
    dataIndex: "sentence_es",
  },
  {
    title: "Sentence - az",
    dataIndex: "sentence_az",
  },
  {
    title: "Əməliyyatlar",
    render: (a) => a.buttons,
  },
];

class Sentences extends React.Component {
  state = {
    sentences: [],
    sentence_es: "",
    sentence_az: "",
    editingId: null,
    loading: false,
    search_loading: false,
    visible: false,
    selected: null,
    keyword: "",
    per_page: 15,
    page: 1,
    total: 15,
  };

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  cancelEdit = () => {
    this.setState({
      sentence_es: "",
      sentence_az: "",
      editingId: null,
      loading: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  confirm = async (id) => {
    this.setState({ loading: true });
    await sualtap.delete(`sentences/${id}`).then((res) => {
      notification.info({
        message: "Successfull",
        description: "The sentence is successfully deleted",
        placement: "topRight",
        icon: <SmileOutlined />,
      });
      this.fetchSentences();
      this.cancelEdit();
    });
  };

  editSentence = (id) => {
    this.setState({
      editingId: id,
      sentence_es: this.state.sentences.find((c) => c.id === id).sentence_es,
      sentence_az: this.state.sentences.find((c) => c.id === id).sentence_az,
    });
  };

  cancel() {
    console.log("cancelled");
  }

  openSentence = (id) => {
    this.setState({
      selected: id,
      visible: true,
    });
  };

  fetchSentences = async () => {
    const search =
      this.state.keyword !== null
        ? `&key_word=${this.state.keyword.trim()}`
        : "";
    this.setState({ search_loading: true });
    await sualtap
      .get(
        `sentences?page=${this.state.page}&per_page=${this.state.per_page}${search}`
      )
      .then((res) => {
        this.setState({
          search_loading: false,
          total: res.data.total,
          page: res.data.page,
          sentences: res.data.data.map((re) => {
            return {
              key: re.id,
              ...re,
              buttons: (
                <>
                  <Button
                    onClick={() => this.openSentence(re.id)}
                    type="primary"
                  >
                    See
                  </Button>
                  <Button
                    onClick={() => this.editSentence(re.id)}
                    className="ml-5"
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Are you sure delete this sentence?"
                    onConfirm={() => this.confirm(re.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </>
              ),
            };
          }),
        });
      });
  };

  componentDidMount = async () => {
    this.props.toggleLoading(true);
    await this.fetchSentences();
    this.props.toggleLoading(false);
  }
  handleSentenceChange = (e, type) => {
    if (type) {
      this.setState({
        sentence_es: e.target.value,
      });
    } else {
      this.setState({
        sentence_az: e.target.value,
      });
    }
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchSentences();
    });
  };

  addSentence = async (e) => {
    e.preventDefault();
    if (
      !this.state.sentence_es.trim().length ||
      !this.state.sentence_az.trim().length
    ) {
      message.warning("Cümlə boş buraxıla bilməz");
    } else {
      this.setState({ loading: true });
      if (this.state.editingId === null) {
        await sualtap
          .post(
            "sentences",
            JSON.stringify({
              sentence_es: this.state.sentence_es,
              sentence_az: this.state.sentence_az,
            })
          )
          .then((res) => {
            message.success("Cümlə əlavə edildi");
            this.fetchSentences();
            this.cancelEdit();
          });
      } else {
        await sualtap
          .put(
            `sentences/${this.state.editingId}`,
            JSON.stringify({
              id: this.state.editingId,
              sentence_es: this.state.sentence_es,
              sentence_az: this.state.sentence_az,
            })
          )
          .then((res) => {
            message.success("Cümləyə düzəliş edildi");
            this.fetchSentences();
            this.cancelEdit();
          });
      }
    }
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Sentences</h2>
        </div>
        <div className="w-100">
          <div className="b-add-box-right w-100">
            <form onSubmit={this.addSentence}>
              <input
                placeholder="Sentence - es"
                value={this.state.sentence_es}
                onChange={(e) => this.handleSentenceChange(e, true)}
                className="ant-input"
                type="text"
              />
              <input
                placeholder="Sentence - az"
                value={this.state.sentence_az}
                onChange={(e) => this.handleSentenceChange(e, false)}
                className="ant-input mt-10"
                type="text"
              />
              <Button htmlType="submit" type="primary" className="mt-10">
                {this.state.editingId !== null
                  ? "Edit sentence"
                  : "Add new sentence"}
                {this.state.loading ? <Spin /> : null}
              </Button>
              {this.state.editingId !== null ? (
                <Button
                  onClick={this.cancelEdit}
                  type="primary"
                  className="mt-10 ml-5"
                >
                  Cancel edit
                </Button>
              ) : null}
            </form>
          </div>
          <div className="b-add-box-left w-100 mt-10">
            <Search
              placeholder="Search a sentence"
              loading={this.state.search_loading}
              disabled={this.state.search_loading}
              value={this.state.keyword}
              onChange={(e) => this.handleKeywordChange(e.target.value)}
              onSearch={this.fetchSentences}
              className="mb-10"
              enterButton
            />
            <Table
              columns={columns}
              dataSource={this.state.sentences}
              size="middle"
              pagination={{
                pageSize: this.state.per_page,
                total: this.state.total,
                current_page: this.state.page,
              }}
              onChange={this.changePage}
            />
          </div>
        </div>
        <Modal
          title="Basic Modal"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          style={{ paddingBottom: "30px" }}
        >
          {this.state.selected !== null
            ? this.state.sentences.find((s) => s.id === this.state.selected)
                .sentence_az
            : "Sentence az"}
          <br />
          {this.state.selected !== null
            ? this.state.sentences.find((s) => s.id === this.state.selected)
                .sentence_es
            : "Sentence es"}
        </Modal>
      </>
    );
  }
}

export default connect(null, {toggleLoading})(Sentences);