import React from "react";
import {
  Table,
  Select,
  Spin,
  Popconfirm,
  Button,
  message,
  Row,
  Col,
  notification,
  Input,
  Switch,
} from "antd";
import sualtap from "./../../../const/api";
import { SmileOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { toggleLoading } from "../../../redux/actions";
const { Option } = Select;
const { Search } = Input;

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Word",
    dataIndex: "word",
  },
  {
    title: "Tərcümə sayı",
    dataIndex: "translations_count",
  },
  {
    title: "Əməliyyatlar",
    render: (a) => a.buttons,
  },
];

class Words extends React.Component {
  state = {
    words: [],
    word: "",
    type: "az",
    filterType: "es",
    editingId: null,
    loading: false,
    total: 10000,
    page: 1,
    per_page: 10,
    keyword: "",
    selectedRows: [],
    has_no_translations: false,
  };

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  cancelEdit = () => {
    this.setState({
      word: "",
      editingId: null,
      loading: false,
      type: "az",
    });
  };

  loadingOn() {
    this.setState({ loading: true });
  }

  confirm = async (id) => {
    this.loadingOn();
    await sualtap.delete(`words/${id}`).then((res) => {
      notification.info({
        message: "Successfull",
        description: "The word is successfully deleted",
        placement: "topRight",
        icon: <SmileOutlined />,
      });
      this.fetchWords();
      this.cancelEdit();
    });
  };

  editWord = (id) => {
    this.setState({
      editingId: id,
      word: this.state.words.find((c) => c.id === id).word,
      type: this.state.words.find((c) => c.id === id).type,
    });
  };

  cancel() {
    console.log("cancelled");
  }

  fetchWords = async () => {
    this.setState({ search_loading: true });
    await sualtap
      .get("words", {
        params: {
          type: this.state.filterType,
          page: this.state.page,
          per_page: this.state.per_page,
          word: this.state.keyword,
          has_no_translations: this.state.has_no_translations ? 1 : 0,
        },
      })
      .then((res) => {
        this.setState({
          total: res.data.total,
          page: res.data.current_page,
          search_loading: false,
          words: res.data.data.map((re) => {
            return {
              key: re.id,
              ...re,
              buttons: (
                <>
                  <Popconfirm
                    title="Are you sure delete this word?"
                    onConfirm={() => this.confirm(re.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                  <Button type="primary" className="ml-5">
                    <Link to={`word/${re.id}`}>Bax</Link>
                  </Button>
                </>
              ),
            };
          }),
        });
      });
  };

  async componentDidMount() {
    if (!this.props.modal) {
      this.props.toggleLoading(true);
      await this.fetchWords(null);
      this.props.toggleLoading(false);
    }
  }
  handleWordChange = (e) => {
    this.setState({
      word: e.target.value,
    });
  };

  addWord = async (e) => {
    e.preventDefault();
    this.loadingOn();
    if (!this.state.word.trim().length) {
      message.warning("Söz boş buraxıla bilməz");
    } else {
      if (this.state.editingId === null) {
        await sualtap
          .post(
            "words",
            JSON.stringify({
              word: this.state.word,
              type: this.state.type,
            })
          )
          .then((res) => {
            message.success("Word added");
            this.fetchWords();
          })
          .finally(() => {
            this.cancelEdit();
          });
      } else {
        await sualtap
          .put(
            `words/${this.state.editingId}`,
            JSON.stringify({
              id: this.state.editingId,
              word: this.state.word,
              type: this.state.type,
            })
          )
          .then((res) => {
            message.success("Word edited");
            this.fetchWords();
            this.cancelEdit();
          });
      }
    }
  };
  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchWords();
    });
  };

  onTypeChange = (type) => {
    this.setState({
      type,
    });
  };

  handleFilterTypeChange = (filterType) => {
    this.setState({ filterType }, () => {
      this.fetchWords();
    });
  };

  handleSelectionChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRows });
  };

  handleSwitchChange = () => {
    const sw = this.state.has_no_translations;
    this.setState({ has_no_translations: !sw });
  };

  deleteSelectedItems = () => {
    sualtap
      .post("words/delete", {
        word_ids: this.state.selectedRows.map((s) => s.id),
      })
      .then((res) => {
        this.fetchWords();
      });
  };

  render() {
    return (
      <>
        {!this.props.modal && (
          <div className="b-page-top">
            <h2>Words</h2>
          </div>
        )}
        <div className="b-add-box">
          {!this.props.modal && (
            <div className="b-add-box-left">
              <Row gutter={8}>
                <Col span={18}>
                  <Search
                    placeholder="Search a word"
                    loading={this.state.search_loading}
                    disabled={this.state.search_loading}
                    value={this.state.keyword}
                    onChange={(e) => this.handleKeywordChange(e.target.value)}
                    onSearch={this.fetchWords}
                    className="mb-10 search-input"
                    enterButton
                  />
                </Col>
                <Col span={6}>
                  <Select
                    className="w-100"
                    defaultValue="es"
                    value={this.state.filterType}
                    onChange={this.handleFilterTypeChange}
                  >
                    <Option value="es">Spanish</Option>
                    <Option value="az">Azerbaijani</Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Switch
                    checked={this.state.has_no_translations}
                    checkedChildren={"Tərcüməsi olmayanlar sözlər"}
                    onChange={this.handleSwitchChange}
                    unCheckedChildren={"Bütün sözlər"}
                    className="mb-10"
                  />
                </Col>
              </Row>
              <Table
                rowSelection={{
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) => {
                    this.handleSelectionChange(selectedRowKeys, selectedRows);
                  },
                }}
                columns={columns}
                dataSource={this.state.words}
                pagination={{
                  pageSize: this.state.per_page,
                  total: this.state.total,
                  current_page: this.state.page,
                }}
                onChange={this.changePage}
                size="middle"
              />
            </div>
          )}
          <div className={`b-add-box-right ${this.props.modal ? "w-100" : ""}`}>
            <form onSubmit={this.addWord}>
              <input
                placeholder="Word"
                value={this.state.word}
                onChange={this.handleWordChange}
                className="ant-input"
                type="text"
              />
              <Select
                className="b-100 mt-10 fl"
                style={{ width: "100%" }}
                placeholder="Select type"
                value={this.state.type}
                onChange={(e) => this.onTypeChange(e)}
              >
                <Option value="az">Azerbaijani</Option>
                <Option value="es">Spanish</Option>
              </Select>
              <Button htmlType="submit" type="primary" className="mt-10">
                {this.state.editingId !== null ? "Edit words" : "Add new word"}
                {this.state.loading ? <Spin /> : null}
              </Button>
              {this.state.selectedRows.length > 0 && (
                <Popconfirm
                  title="Bu sözləri silmək istədiyinizdən əminsiniz?"
                  onConfirm={this.deleteSelectedItems}
                >
                  <Button type="danger" className="mt-10 ml-5">
                    Delete selected words
                  </Button>
                </Popconfirm>
              )}
              {this.state.editingId !== null ? (
                <Button
                  onClick={this.cancelEdit}
                  type="primary"
                  className="mt-10 ml-5"
                >
                  Cancel edit
                </Button>
              ) : null}
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Words);
