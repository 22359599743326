import React, { Component } from "react";
import {
  Input,
  Col,
  Row,
  Tabs,
  Button,
  message,
  Spin,
  InputNumber,
} from "antd";
import Uploader from "./../../Elements/Uploader";
import sualtap from "../../../const/api";
import history from "./../../../const/history";
import { connect } from "react-redux";
import { toggleLoading } from "./../../../redux/actions";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { TabPane } = Tabs;

class Service extends Component {
  state = {
    es: {
      title: "",
      description: "",
    },
    az: {
      title: "",
      description: "",
    },
    priorty: 0,
    image: null,
    prevImg: [],
    loading: false,
    editMode: false,
  };

  componentDidMount = async () => {
    if (this.props.params && this.props.params.id) {
      this.props.toggleLoading(true);
      await sualtap.get(`services/${this.props.params.id}/show`).then((res) => {
        let obj = {
          editMode: true,
          es: {
            title: res.data.title_es,
            description: res.data.description_es,
          },
          az: {
            title: res.data.title_az,
            description: res.data.description_az,
          },
          image: "prev",
          priority: res.data.priority || 0,
          prevImg: res.data.image
            ? [
                {
                  uid: "-1",
                  name: "image.png",
                  status: "done",
                  url: res.data.image,
                },
              ]
            : [],
        };
        this.setState({ ...obj });
      });
      this.props.toggleLoading(false);
    }
  };

  handleTextChange = (value, lang, part) => {
    let l = { ...this.state };
    l[lang][part] = value;
    this.setState({
      ...l,
    });
  };

  setImage = (images) => {
    this.setState({ image: images.length ? images[0].originFileObj : null });
  };

  isNullOrEmpty(str) {
    return str == null || !str.length;
  }

  validateService() {
    const { az, es, image } = this.state;
    if (
      this.isNullOrEmpty(az.description) ||
      this.isNullOrEmpty(es.description) ||
      this.isNullOrEmpty(az.title) ||
      this.isNullOrEmpty(es.title) ||
      image === null
    ) {
      return false;
    }
    return true;
  }

  saveService = async () => {
    if (this.validateService()) {
      var data = new FormData();
      const { az, es, image, priority } = this.state;
      data.append("description_az", az.description);
      data.append("description_es", es.description);
      data.append("priority", priority);
      data.append("title_az", az.title);
      data.append("title_es", es.title);
      if (image !== "prev" && image !== null) {
        data.append("image", image);
      }
      this.setState({ loading: true });
      if (!this.state.editMode) {
        await sualtap
          .post("services", data)
          .then((res) => {
            message.success("Xidmət əlavə olundu");
            history.push("/services");
          })
          .catch(() => {
            this.setState({ loading: false });
            message.warning("Xəta baş verdi");
          });
      } else {
        await sualtap
          .post(`services/${this.props.params.id}/update`, data)
          .then((res) => {
            message.success("Düzəliş edildi");
            history.push("/services");
          })
          .catch(() => {
            this.setState({ loading: false });
            message.warning("Xəta baş verdi");
          });
      }
    } else {
      message.warning("Bütün məlumatlar doldurulmalıdır.");
    }
  };

  handlePriorityChange = (e) => {
    this.setState({ priority: e });
  };

  render() {
    return (
      <div className="App">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Azerbaijani" key="1">
            <Row gutter={16}>
              <Col span={8}>
                <Col span={24}>
                  <Input
                    placeholder="Title"
                    className="mb-10"
                    value={this.state.az.title}
                    onChange={(e) =>
                      this.handleTextChange(e.target.value, "az", "title")
                    }
                  />
                </Col>
                <Col span={24}>
                  <label className="bold">Priority</label>
                </Col>
                <Col span={24}>
                  <InputNumber
                    value={this.state.priority}
                    onChange={this.handlePriorityChange}
                  />
                </Col>
              </Col>
              <Col span={16}>
                <CKEditor
                  id="azEditor"
                  editor={ClassicEditor}
                  data={this.state.az.description}
                  onChange={(event, editor) => {
                    this.handleTextChange(
                      editor.getData(),
                      "az",
                      "description"
                    );
                  }}
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "https://ispanadicto.site/api/editor/image?token=" +
                        localStorage.getItem("access_token"),
                      options: {
                        resourceType: "Images",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Spanish" key="2">
            <Row gutter={16}>
              <Col span={8}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.es.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "es", "title")
                  }
                />
              </Col>
              <Col span={16}>
                <CKEditor
                  id="esEditor"
                  editor={ClassicEditor}
                  data={this.state.es.description}
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "https://ispanadicto.site/api/editor/image?token=" +
                        localStorage.getItem("access_token"),
                      options: {
                        resourceType: "Images",
                      },
                    },
                  }}
                  onChange={(event, editor) => {
                    this.handleTextChange(
                      editor.getData(),
                      "es",
                      "description"
                    );
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <div className="mt-10">
          <Uploader
            prevImg={this.state.prevImg}
            setFile={this.setImage}
            multiple={false}
            count={1}
          />
        </div>
        <Button
          onClick={this.saveService}
          size="large"
          className="mt-10 fr"
          type="primary"
        >
          Yadda saxla
          {this.state.loading ? <Spin /> : null}
        </Button>
      </div>
    );
  }
}
export default connect(null, { toggleLoading })(Service);
