import React, { Component } from "react";
import {
  Input,
  Col,
  Row,
  Tabs,
  Button,
  message,
  Spin,
  Select,
  Modal,
  Table,
  Tag,
} from "antd";
import Uploader from "../../Elements/Uploader";
import sualtap from "../../../const/api";
import history from "../../../const/history";

import { toggleLoading } from "./../../../redux/actions";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { connect } from "react-redux";

const { TabPane } = Tabs;
const { TextArea, Search } = Input;
const { Option } = Select;

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (t) => (
      <p>
        {t?.name_az}-{t?.name_es}
      </p>
    ),
  },
  {
    title: "Azerbaijani",
    dataIndex: "azerbaijani",
    key: "azerbaijani",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Spanish",
    dataIndex: "spanish",
    key: "spanish",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Text extends Component {
  state = {
    es: {
      title: "",
      preview: "",
      body: "",
    },
    az: {
      title: "",
      preview: "",
      body: "",
    },
    image: null,
    prevImg: [],
    loading: false,
    editMode: false,
    levels: [],
    level_id: null,
    keyword: "",
    search_loading: false,
    translations: [],
    page: 1,
    total: 15,
    per_page: 10,
    visible: false,
    prevSelectedIds: [],
    selected: [],
    selectedIds: [],
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.getTranslations();
    });
  };

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  addToText = (tr) => {
    if (!this.state.selectedIds.includes(tr.id)) {
      let selected = [...this.state.selected];
      let selectedIds = [...this.state.selectedIds];
      selected.push(tr);
      selectedIds.push(tr.id);
      this.setState({ selected, selectedIds });
    } else {
      message.warning("Bu söz artıq seçilib");
    }
  };

  removeFromSelected = (id) => {
    let selected = [];
    let selectedIds = [];
    this.state.selected.forEach((s) => {
      console.log(s);
      if (s.id !== id) {
        console.log(s);
        selected.push(s);
        selectedIds.push(s.id);
      }
    });
    this.setState({ selected, selectedIds });
  };

  getTranslations = async () => {
    const search =
      this.state.keyword !== null ? `&key_word=${this.state.keyword}` : "";
    this.setState({ search_loading: true });
    await sualtap
      .get(
        `translations?page=${this.state.page}&per_page=${this.state.per_page}${search}`
      )
      .then((res) => {
        res.data.data.forEach((d, index) => {
          d.key = index;
          d.buttons = (
            <>
              <Button onClick={() => this.addToText(d)} className="ml-10">
                Add to text
              </Button>
            </>
          );
        });
        this.setState({
          translations: res.data.data,
          total: res.data.total,
          page: res.data.current_page,
          search_loading: false,
        });
      });
  };

  handleLevelChange = (e) => {
    this.setState({ level_id: e });
  };

  setModalVisible = (visible) => {
    this.setState({ visible });
    if (visible) {
      this.getTranslations();
    } else {
      this.setState({ keyword: "" });
    }
  };

  getLevels = async () => {
    await sualtap.get("info").then((res) => {
      this.setState({ levels: res.data.level });
    });
  };

  componentDidMount = async () => {
    this.getLevels();
    if (this.props.params && this.props.params.id) {
      this.props.toggleLoading(true);
      await sualtap.get(`texts/${this.props.params.id}/show`).then((res) => {
        let obj = {
          editMode: true,
          es: {
            title: res.data.title_es,
            preview: res.data.preview_es,
            body: res.data.body_es,
          },
          az: {
            title: res.data.title_az,
            preview: res.data.preview_az,
            body: res.data.body_az,
          },
          level_id: +res.data.level_id,
          image: "prev",
          prevImg: res.data.image
            ? [
                {
                  uid: "-1",
                  name: "image.png",
                  status: "done",
                  url: res.data.image,
                },
              ]
            : [],
          prevSelectedIds: res.data.translations.map((t) => t.id),
          selectedIds: res.data.translations.map((t) => t.id),
          selected: res.data.translations.map((t) => {
            return {
              spanish: t.spanish,
              azerbaijani: t.azerbaijani,
              id: t.id,
            };
          }),
        };
        this.setState({ ...obj });
      });
      this.props.toggleLoading(false);
    }
  };

  handleTextChange = (value, lang, part) => {
    let l = { ...this.state };
    l[lang][part] = value;
    this.setState({
      ...l,
    });
  };

  setImage = (images) => {
    this.setState({ image: images.length ? images[0].originFileObj : null });
  };

  isNullOrEmpty(str) {
    return str == null || !str.length;
  }

  validateService() {
    const { az, es, image, selectedIds } = this.state;
    if (
      this.isNullOrEmpty(az.body) ||
      this.isNullOrEmpty(es.body) ||
      this.isNullOrEmpty(az.title) ||
      this.isNullOrEmpty(es.title) ||
      this.isNullOrEmpty(az.preview) ||
      this.isNullOrEmpty(es.preview) ||
      image === null ||
      selectedIds.length < 3
    ) {
      return false;
    }
    return true;
  }

  handleTranslations = async () => {
    let newIds = [];
    await this.state.prevSelectedIds.forEach(async (p) => {
      if (!this.state.selectedIds.includes(p)) {
        await sualtap.delete(`texts/${this.props.params.id}/translate/${p}`);
      }
    });
    this.state.selectedIds.forEach((s) => {
      if (!this.state.prevSelectedIds.includes(s)) {
        newIds.push(s);
      }
    });
    if (newIds.length) {
      await sualtap.post(`texts/${this.props.params.id}/translate`, {
        translations_ids: newIds,
      });
    }
  };

  saveService = async () => {
    if (this.validateService()) {
      var data = new FormData();
      const { az, es, image, selectedIds, level_id } = this.state;
      data.append("body_az", az.body);
      data.append("body_es", es.body);
      data.append("title_az", az.title);
      data.append("title_es", es.title);
      data.append("preview_az", az.preview);
      data.append("preview_es", es.preview);
      data.append("level_id", level_id);
      if (!this.state.editMode) {
        selectedIds.forEach((s) => {
          data.append("translations_ids[]", s);
        });
      }
      if (image !== "prev" && image !== null) {
        data.append("image", image);
      }
      this.setState({ loading: true });
      if (!this.state.editMode) {
        await sualtap
          .post("texts", data)
          .then((res) => {
            message.success("Xidmət əlavə olundu");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
          history.push("/texts");
      } else {
        this.handleTranslations();
        await sualtap
          .post(`texts/${this.props.params.id}/update`, data)
          .then((res) => {
            message.success("Düzəliş edildi");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
        history.push("/texts");
      }
    } else {
      if (this.state.selectedIds.length < 3) {
        message.warning("Ən az 3 söz olmalıdır.");
      } else {
        message.warning("Bütün məlumatlar doldurulmalıdır.");
      }
    }
  };

  render() {
    return (
      <div className="App">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Azerbaijani" key="1">
            <Row gutter={16}>
              <Col span={8}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.az.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "az", "title")
                  }
                />
                <TextArea
                  placeholder="Preview"
                  className="mb-10"
                  value={this.state.az.preview}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "az", "preview")
                  }
                />
              </Col>
              <Col span={16}>
                <CKEditor
                  id="azEditor"
                  editor={ClassicEditor}
                  data={this.state.az.body}
                  onChange={(event, editor) => {
                    this.handleTextChange(editor.getData(), "az", "body");
                  }}
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "https://ispanadicto.site/api/editor/image?token=" +
                        localStorage.getItem("access_token"),
                      options: {
                        resourceType: "Images",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Spanish" key="2">
            <Row gutter={16}>
              <Col span={8}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.es.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "es", "title")
                  }
                />
                <TextArea
                  placeholder="Preview"
                  className="mb-10"
                  value={this.state.es.preview}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "es", "preview")
                  }
                />
              </Col>
              <Col span={16}>
                <CKEditor
                  id="esEditor"
                  editor={ClassicEditor}
                  data={this.state.es.body}
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "https://ispanadicto.site/api/editor/image?token=" +
                        localStorage.getItem("access_token"),
                      options: {
                        resourceType: "Images",
                      },
                    },
                  }}
                  onChange={(event, editor) => {
                    this.handleTextChange(editor.getData(), "es", "body");
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <div className="mt-10">
          <Select
            value={this.state.level_id}
            placeholder="Səviyyə seçin"
            style={{ width: 200 }}
            className="mb-10"
            onChange={(e) => this.handleLevelChange(e)}
          >
            {this.state.levels.map((l) => {
              return (
                <Option value={l.id} key={l.id}>
                  {l.name}
                </Option>
              );
            })}
          </Select>
          <Button className="ml-5" onClick={() => this.setModalVisible(true)}>
            Select words
          </Button>
          <div className="w-100 mb-10">
            {this.state.selected.map((s, index) => {
              return (
                <Tag
                  color="#108ee9"
                  key={s.id}
                  closable
                  onClose={() => this.removeFromSelected(s.id)}
                >
                  {s.azerbaijani.word} | {s.spanish.word}
                </Tag>
              );
            })}
          </div>
          <Uploader
            prevImg={this.state.prevImg}
            setFile={this.setImage}
            multiple={false}
            count={1}
          />
        </div>
        <Button
          onClick={this.saveService}
          size="large"
          className="mt-10 fr"
          type="primary"
        >
          Yadda saxla
          {this.state.loading ? <Spin /> : null}
        </Button>
        <Modal
          title="Select translations"
          visible={this.state.visible}
          style={{ top: 20 }}
          width={720}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
        >
          <Search
            placeholder="Search a translation"
            loading={this.state.search_loading}
            disabled={this.state.search_loading}
            value={this.state.keyword}
            onChange={(e) => this.handleKeywordChange(e.target.value)}
            onSearch={this.getTranslations}
            className="mb-10"
            enterButton
          />
          <Table
            dataSource={this.state.translations}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(null, { toggleLoading })(Text);
