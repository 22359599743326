import React from "react";
import { Tabs, Button, Input, Col, Row, message } from "antd";
import ConjugationTable from "./ConjugationTable";
import sualtap from "../../../const/api";

const { TabPane } = Tabs;

const tables = [
  {
    name: "Indicativo",
    key: "indicative",
    has: true,
  },
  {
    name: "Imperative",
    key: "imperative",
    has: true,
  },
  {
    name: "Continuo",
    key: "continuous",
    has: true,
  },
  {
    name: "Perfecto",
    key: "perfect",
    has: true,
  },
  {
    name: "Subjunctivo",
    key: "subjunctive",
    has: true,
  },
  {
    name: "Perfecto de Subjunctivo",
    key: "perfect_subjunctive",
    has: true,
  },
];

let columns = {
  indicative: ["Presente", "Pretérito", "Imperfecto", "Condicional", "Futuro"],
  imperative: ["Presente", "Imperfecto", "Imperfecto 2", "Futuro"],
  continuous: ["Affirmativo", "Negativo"],
  perfect: ["Presente", "Pretérito", "Imperfecto 2", "Condicional"],
  subjunctive: ["Presente", "Pretérito", "Pasado", "Condicional", "Futuro"],
  perfect_subjunctive: ["Presente", "Pasado", "Futuro"],
};

const initialPanes = tables.map((t, index) => {
  return { title: t.name, cols: t.key, key: index.toString() };
});

const initialData = {
  indicative: {
    data: {
      yo: ["", "", "", "", ""],
      tú: ["", "", "", "", ""],
      "él/ella/Ud.": ["", "", "", "", ""],
      nosotros: ["", "", "", "", ""],
      vosotros: ["", "", "", "", ""],
      "ellos/ellas/Uds.": ["", "", "", "", ""],
    },
    hide: false,
    headers: ["Presente", "Pretérito", "Imperfecto", "Condicional", "Futuro"],
  },
  imperative: {
    data: {
      yo: ["", "", "", ""],
      tú: ["", "", "", ""],
      "él/ella/Ud.": ["", "", "", ""],
      nosotros: ["", "", "", ""],
      vosotros: ["", "", "", ""],
      "ellos/ellas/Uds.": ["", "", "", ""],
    },
    hide: false,
    headers: ["Presente", "Imperfecto", "Imperfecto 2", "Futuro"],
  },
  continuous: {
    data: {
      yo: ["", ""],
      tú: ["", ""],
      "él/ella/Ud.": ["", ""],
      nosotros: ["", ""],
      vosotros: ["", ""],
      "ellos/ellas/Uds.": ["", ""],
    },
    hide: false,
    headers: ["Affirmativo", "Negativo"],
  },
  perfect: {
    data: {
      yo: ["", "", "", ""],
      tú: ["", "", "", ""],
      "él/ella/Ud.": ["", "", "", ""],
      nosotros: ["", "", "", ""],
      vosotros: ["", "", "", ""],
      "ellos/ellas/Uds.": ["", "", "", ""],
    },
    hide: false,
    headers: ["Presente", "Pretérito", "Imperfecto 2", "Condicional"],
  },
  subjunctive: {
    data: {
      yo: ["", "", "", "", ""],
      tú: ["", "", "", "", ""],
      "él/ella/Ud.": ["", "", "", "", ""],
      nosotros: ["", "", "", "", ""],
      vosotros: ["", "", "", "", ""],
      "ellos/ellas/Uds.": ["", "", "", "", ""],
    },
    hide: false,
    headers: ["Presente", "Pretérito", "Pasado", "Condicional", "Futuro"],
  },
  perfect_subjunctive: {
    data: {
      yo: ["", "", ""],
      tú: ["", "", ""],
      "él/ella/Ud.": ["", "", ""],
      nosotros: ["", "", ""],
      vosotros: ["", "", ""],
      "ellos/ellas/Uds.": ["", "", ""],
    },
    hide: false,
    headers: ["Presente", "Pasado", "Futuro"],
  },
};

class Conjugation extends React.Component {
  state = {
    present: "",
    past: "",
    activeKey: null,
    panes: initialPanes,
    word_id: this.props.word_id,
    data: { ...initialData },
    id: null,
  };

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  componentDidMount() {
    sualtap.get(`conjugations?word_id=${this.props.word_id}`).then((res) => {
      if (!this.isEmpty(res.data)) {
        this.setState(
          {
            past: res.data.past,
            present: res.data.present,
            id: res.data.id,
            data: {
              indicative: res.data.indicative,
              subjunctive: res.data.subjunctive,
              imperative: res.data.imperative,
              continuous: res.data.continuous,
              perfect: res.data.perfect,
              perfect_subjunctive: res.data.perfect_subjunctive,
              activeKey: "0",
            },
          },
          () => {
            console.log(this.state);
          }
        );
      }
    });
  }

  handleHideChange = (bool, key) => {
    const data = { ...this.state.data };
    data[key].hide = bool;
    this.setState({ data });
  };

  handleWordChange = (e, key) => {
    const word = { ...this.state };
    word[key] = e.target.value;
    this.setState({ ...word });
  };

  onChange = (activeKey) => {
    this.setState({ activeKey });
  };

  saveData = (tableData, key) => {
    const data = { ...this.state.data };
    data[key]["data"] = tableData;
    this.setState({ data });
  };

  saveConjugation = async () => {
    if (this.state.past.length && this.state.present.length) {
      let obj = {
        ...this.state.data,
        present: this.state.present,
        past: this.state.past,
        word_id: +this.state.word_id,
      };
      if (!this.state.id) {
        await sualtap.post("conjugations", obj).then((res) => {
          message.success("Uğurla əlavə olundu");
        });
      } else {
        await sualtap.put("conjugations/" + this.state.id, obj).then((res) => {
          message.success("Uğurla düzəliş edildi");
        });
      }
    } else {
      message.warning("Məlumatlar doldurulmalıdır.");
    }
  };

  render() {
    const { panes, activeKey } = this.state;
    return (
      <>
        <Row gutter={16}>
          <Col span={10}>
            <Input
              value={this.state.present}
              onChange={(e) => this.handleWordChange(e, "present")}
              placeholder="Present"
            />
          </Col>
          <Col span={10}>
            <Input
              value={this.state.past}
              onChange={(e) => this.handleWordChange(e, "past")}
              placeholder="Past"
            />
          </Col>
          <Col span={4}>
            <Button onClick={this.saveConjugation} type="primary">
              Save conjugation
            </Button>
          </Col>
        </Row>
        <Tabs onChange={this.onChange} activeKey={activeKey}>
          {panes.map((pane) => (
            <TabPane tab={pane.title} key={pane.key}>
              <ConjugationTable
                saveData={this.saveData}
                name={pane.cols}
                data={this.state.data[pane.cols]}
                columns={columns[pane.cols]}
                handleHide={this.handleHideChange}
              />
            </TabPane>
          ))}
        </Tabs>
      </>
    );
  }
}

export default Conjugation;
