import React, { Component } from "react";
import {
  Input,
  Col,
  Row,
  Tabs,
  Button,
  message,
  Spin,
  Select,
  Modal,
  Table,
  Tag,
} from "antd";
import Uploader from "../../Elements/Uploader";
import sualtap from "../../../const/api";
import { toggleLoading } from "./../../../redux/actions";
import history from "./../../../const/history";
import { connect } from "react-redux";

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const pcolumns = [
  {
    title: "Title Az",
    dataIndex: "title_az",
    key: "title_az",
  },
  {
    title: "Title Es",
    dataIndex: "title_es",
    key: "title_es",
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

const columns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (t) => (
      <p>
        {t?.name_az}-{t?.name_es}
      </p>
    ),
  },
  {
    title: "Azerbaijani",
    dataIndex: "azerbaijani",
    key: "azerbaijani",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Spanish",
    dataIndex: "spanish",
    key: "spanish",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Voc extends Component {
  state = {
    es: {
      title: "",
    },
    az: {
      title: "",
    },
    image: null,
    prevImg: [],
    loading: false,
    editMode: false,
    levels: [],
    level_id: null,
    keyword: "",
    phrases_keyword: "",
    search_loading: false,
    translations: [],
    phrases: [],
    page: 1,
    phrases_page: 1,
    total: 15,
    phrases_total: 15,
    per_page: 10,
    phrases_per_page: 10,
    visible: false,
    phrasesVisible: false,
    prevSelectedIds: [],
    prevSelectedPhraseIds: [],
    selected: [],
    selectedPhrases: [],
    selectedIds: [],
    selectedPhraseIds: [],
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.getTranslations();
    });
  };

  changePhrasesPage = (e) => {
    this.setState(
      { phrases_page: e.current, phrases_per_page: e.pageSize },
      () => {
        this.getPhrases();
      }
    );
  };

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  handlePhrasesKeywordChange = (phrases_keyword) => {
    this.setState({ phrases_keyword });
  };

  addToText = (tr) => {
    if (!this.state.selectedIds.includes(tr.id)) {
      let selected = [...this.state.selected];
      let selectedIds = [...this.state.selectedIds];
      selected.push(tr);
      selectedIds.push(tr.id);
      this.setState({ selected, selectedIds });
    } else {
      message.warning("Bu söz artıq seçilib");
    }
  };

  addToTextPhrase = (d) => {
    if (!this.state.selectedPhraseIds.includes(d.id)) {
      let selectedPhrases = [...this.state.selectedPhrases];
      let selectedPhraseIds = [...this.state.selectedPhraseIds];
      selectedPhrases.push(d);
      selectedPhraseIds.push(d.id);
      this.setState({ selectedPhrases, selectedPhraseIds });
    } else {
      message.warning("Bu ifadə artıq seçilib");
    }
  };

  removeFromSelected = (id) => {
    let selected = [...this.state.selected.filter((s) => s.id !== id)];
    let selectedIds = [...this.state.selectedIds.filter((s) => s !== id)];
    this.setState({ selected, selectedIds });
  };

  removeFromSelectedPhrases = (id) => {
    let selectedPhrases = [
      ...this.state.selectedPhrases.filter((s) => s.id !== id),
    ];
    let selectedPhraseIds = [
      ...this.state.selectedPhraseIds.filter((s) => s !== id),
    ];
    this.setState({ selectedPhrases, selectedPhraseIds });
  };

  getTranslations = async () => {
    const search =
      this.state.keyword !== null ? `&key_word=${this.state.keyword}` : "";
    this.setState({ search_loading: true });
    await sualtap
      .get(
        `translations?page=${this.state.page}&per_page=${this.state.per_page}${search}`
      )
      .then((res) => {
        res.data.data.forEach((d, index) => {
          d.key = index;
          d.buttons = (
            <>
              <Button onClick={() => this.addToText(d)} className="ml-10">
                Add to vocabulary
              </Button>
            </>
          );
        });
        this.setState({
          translations: res.data.data.filter(
            (d) => !this.state.selectedIds.includes(d.id)
          ),
          total: res.data.total,
          page: res.data.current_page,
          search_loading: false,
        });
      });
  };

  handleLevelChange = (e) => {
    this.setState({ level_id: e });
  };

  setModalVisible = (visible) => {
    this.setState({ visible });
    if (visible) {
      this.getTranslations();
    } else {
      this.setState({ keyword: "" });
    }
  };

  getPhrases = async () => {
    const search =
      this.state.phrases_keyword !== null
        ? `&compact=1&key_word=${this.state.phrases_keyword}`
        : "";
    this.setState({ search_loading: true });
    await sualtap
      .get(
        `phrases?page=${this.state.phrases_page}&per_page=${this.state.phrases_per_page}${search}`
      )
      .then((res) => {
        res.data.data.forEach((d, index) => {
          d.key = index;
          d.buttons = (
            <>
              <Button onClick={() => this.addToTextPhrase(d)} className="ml-10">
                Add to vocabulary
              </Button>
            </>
          );
        });
        this.setState({
          phrases: res.data.data.filter(
            (d) => !this.state.selectedPhraseIds.includes(d.id)
          ),
          total: res.data.total,
          page: res.data.current_page,
          search_loading: false,
        });
      });
  };

  setPhrasesModalVisible = (phrasesVisible) => {
    this.setState({ phrasesVisible });
    if (phrasesVisible) {
      this.getPhrases();
    } else {
      this.setState({ phrases_keyword: "" });
    }
  };

  getLevels = async () => {
    await sualtap.get("info").then((res) => {
      this.setState({ levels: res.data.level });
    });
  };

  componentDidMount = async () => {
    this.getLevels();
    if (this.props.params && this.props.params.id) {
      this.props.toggleLoading(true);
      await sualtap
        .get(`vocabularies/${this.props.params.id}/show`)
        .then((res) => {
          let obj = {
            editMode: true,
            es: {
              title: res.data.title_es,
            },
            az: {
              title: res.data.title_az,
            },
            level_id: +res.data.level_id,
            image: "prev",
            prevImg: res.data.images
              ? [
                  {
                    uid: "-1",
                    name: "image.png",
                    status: "done",
                    url: res.data.images,
                  },
                ]
              : [],
            prevSelectedIds: res.data.translations.map((t) => t.id),
            prevSelectedPhraseIds: res.data.phrases?.map((t) => t.id) || [],
            selectedIds: res.data.translations.map((t) => t.id),
            selectedPhraseIds: res.data.phrases?.map((t) => t.id) || [],
            selectedPhrases:
              res.data.phrases?.map((t) => {
                return {
                  title_az: t.title_az,
                  title_es: t.title_es,
                  id: t.id,
                };
              }) || [],
            selected: res.data.translations.map((t) => {
              return {
                spanish: t.spanish,
                azerbaijani: t.azerbaijani,
                id: t.id,
              };
            }),
          };
          this.setState({ ...obj });
        });
      this.props.toggleLoading(false);
    }
  };

  handleTextChange = (value, lang, part) => {
    let l = { ...this.state };
    l[lang][part] = value;
    this.setState({
      ...l,
    });
  };

  setImage = (images) => {
    this.setState({ image: images.length ? images[0].originFileObj : null });
  };

  isNullOrEmpty(str) {
    return str == null || !str.length;
  }

  validateService() {
    const { az, es, image, selectedIds } = this.state;
    if (
      this.isNullOrEmpty(es.title) ||
      this.isNullOrEmpty(az.title) ||
      this.isNullOrEmpty(es.title) ||
      image === null ||
      selectedIds.length < 3
    ) {
      return false;
    }
    return true;
  }

  handleTranslations = async () => {
    let newIds = [];
    await this.state.prevSelectedIds.forEach(async (p) => {
      if (!this.state.selectedIds.includes(p)) {
        await sualtap.delete(
          `vocabularies/${this.props.params.id}/translate/${p}`
        );
      }
    });
    this.state.selectedIds.forEach((s) => {
      if (!this.state.prevSelectedIds.includes(s)) {
        newIds.push(s);
      }
    });
    if (newIds.length) {
      await sualtap.post(`vocabularies/${this.props.params.id}/translate`, {
        translations_ids: newIds,
      });
    }
  };

  handlePhrases = async () => {
    let newIds = [];
    await this.state.prevSelectedPhraseIds.forEach(async (p) => {
      if (!this.state.selectedPhraseIds.includes(p)) {
        await sualtap.delete(
          `vocabularies/${this.props.params.id}/phrases/${p}`
        );
      }
    });
    this.state.selectedPhraseIds.forEach((s) => {
      if (!this.state.prevSelectedPhraseIds.includes(s)) {
        newIds.push(s);
      }
    });
    if (newIds.length) {
      await sualtap.post(`vocabularies/${this.props.params.id}/phrases`, {
        phrases_ids: newIds,
      });
    }
  };

  saveVocabulary = async () => {
    if (this.validateService()) {
      var data = new FormData();
      const { az, es, image, selectedIds, level_id } = this.state;
      data.append("title_az", az.title);
      data.append("title_es", es.title);
      data.append("description_es", "desc es");
      data.append("description_az", "desc az");
      data.append("level_id", level_id);
      if (!this.state.editMode) {
        selectedIds.forEach((s) => {
          data.append("translations_ids[]", s);
        });
      }
      if (image !== "prev" && image !== null) {
        data.append("images", image);
      }
      this.setState({ loading: true });
      if (!this.state.editMode) {
        await sualtap
          .post("vocabularies", data)
          .then((res) => {
            message.success("Lüğət əlavə olundu");
            history.push("/vocabulary");
          })
          .catch(() => {
            this.setState({ loading: false });
            message.warning("Xəta baş verdi");
          });
      } else {
        this.handleTranslations();
        this.handlePhrases();
        await sualtap
          .post(`vocabularies/${this.props.params.id}/update`, data)
          .then((res) => {
            message.success("Düzəliş edildi");
            history.push("/vocabulary");
          })
          .catch(() => {
            this.setState({ loading: false });
            message.warning("Xəta baş verdi");
          });
      }
    } else {
      if (this.state.selectedIds.length < 3) {
        message.warning("Ən az 3 söz olmalıdır.");
      } else {
        message.warning("Bütün məlumatlar doldurulmalıdır.");
      }
    }
  };

  render() {
    return (
      <div className="App">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Azerbaijani" key="1">
            <Row gutter={16}>
              <Col span={24}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.az.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "az", "title")
                  }
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Spanish" key="2">
            <Row gutter={16}>
              <Col span={24}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.es.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "es", "title")
                  }
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <div className="mt-10">
          <Select
            value={this.state.level_id}
            placeholder="Səviyyə seçin"
            style={{ width: 200 }}
            className="mb-10"
            onChange={(e) => this.handleLevelChange(e)}
          >
            {this.state.levels.map((l) => {
              return (
                <Option value={l.id} key={l.id}>
                  {l.name}
                </Option>
              );
            })}
          </Select>
          <Button className="ml-5" onClick={() => this.setModalVisible(true)}>
            Select words
          </Button>
          <Button
            className="ml-5"
            onClick={() => this.setPhrasesModalVisible(true)}
          >
            Select phrases
          </Button>
          <div className="w-100 mb-10">
            {this.state.selected.map((s) => {
              return (
                <Tag
                  color="#108ee9"
                  key={s.id}
                  closable
                  onClose={() => this.removeFromSelected(s.id)}
                >
                  {s.azerbaijani?.word} | {s.spanish?.word}
                </Tag>
              );
            })}
          </div>
          <div className="w-100 mb-10">
            {this.state.selectedPhrases.map((s) => {
              return (
                <Tag
                  color="red"
                  key={s.id}
                  closable
                  onClose={() => this.removeFromSelectedPhrases(s.id)}
                >
                  {s.title_az} | {s.title_es}
                </Tag>
              );
            })}
          </div>
          <Uploader
            prevImg={this.state.prevImg}
            setFile={this.setImage}
            multiple={false}
            count={1}
          />
        </div>
        <Button
          onClick={this.saveVocabulary}
          size="large"
          className="mt-10 fr"
          type="primary"
        >
          Yadda saxla
          {this.state.loading ? <Spin /> : null}
        </Button>
        <Modal
          title="Select translations"
          visible={this.state.visible}
          style={{ top: 20 }}
          width={720}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
        >
          <Search
            placeholder="Search a translation"
            loading={this.state.search_loading}
            disabled={this.state.search_loading}
            value={this.state.keyword}
            onChange={(e) => this.handleKeywordChange(e.target.value)}
            onSearch={this.getTranslations}
            className="mb-10"
            enterButton
          />
          <Table
            dataSource={this.state.translations}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </Modal>

        <Modal
          title="Select phrases"
          visible={this.state.phrasesVisible}
          style={{ top: 20 }}
          width={720}
          onCancel={() => this.setPhrasesModalVisible(false)}
          onOk={() => this.setPhrasesModalVisible(false)}
        >
          <Search
            placeholder="Search a phrase"
            loading={this.state.search_loading}
            disabled={this.state.search_loading}
            value={this.state.phrases_keyword}
            onChange={(e) => this.handlePhrasesKeywordChange(e.target.value)}
            onSearch={this.getPhrases}
            className="mb-10"
            enterButton
          />
          <Table
            dataSource={this.state.phrases}
            columns={pcolumns}
            pagination={{
              pageSize: this.state.phrases_per_page,
              total: this.state.phrases_total,
              current_page: this.state.phrases_page,
            }}
            onChange={this.changePhrasesPage}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(null, { toggleLoading })(Voc);
