import React from "react";
import { Table, Spin, Popconfirm, Button, message, notification } from "antd";
import sualtap from "./../../../const/api";
import { SmileOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { toggleLoading } from "./../../../redux/actions";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name - es",
    dataIndex: "name_es",
  },
  {
    title: "Name - az",
    dataIndex: "name_az",
  },
  {
    title: "Əməliyyatlar",
    render: (a) => a.buttons,
  },
];

class Categories extends React.Component {
  state = {
    categories: [],
    name_es: "",
    name_az: "",
    editingId: null,
    loading: false,
    total: 20,
    page: 1,
    per_page: 20,
  };

  cancelEdit = () => {
    this.setState({
      name_es: "",
      name_az: "",
      editingId: null,
      loading: false,
    });
  };

  loadingOn() {
    this.setState({ loading: true });
  }

  confirm = async (id) => {
    this.loadingOn();
    await sualtap.delete(`categories/${id}`).then((res) => {
      notification.info({
        message: "Successfull",
        description: "The category is successfully deleted",
        placement: "topRight",
        icon: <SmileOutlined />,
      });
      this.fetchCategories();
      this.cancelEdit();
    });
  };

  editCategory = (id) => {
    this.setState({
      editingId: id,
      name_es: this.state.categories.find((c) => c.id === id).name_es,
      name_az: this.state.categories.find((c) => c.id === id).name_az,
    });
  };

  cancel() {
    console.log("cancelled");
  }

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchCategories();
    });
  };

  fetchCategories = async (page) => {
    await sualtap
      .get("categories", {
        params: {
          page: this.state.page,
          per_page: this.state.per_page,
        },
      })
      .then((res) => {
        this.setState({
          page: res.data.current_page,
          total: res.data.total,
          categories: res.data.data.map((re) => {
            return {
              key: re.id,
              ...re,
              buttons: (
                <>
                  <Button onClick={() => this.editCategory(re.id)}>Edit</Button>
                  <Popconfirm
                    title="Are you sure delete this category?"
                    onConfirm={() => this.confirm(re.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </>
              ),
            };
          }),
        });
      });
  };

  async componentDidMount() {
    this.props.toggleLoading(true);
    await this.fetchCategories();
    this.props.toggleLoading(false);
  }
  handleNameChange = (e, type) => {
    if (type) {
      this.setState({
        name_es: e.target.value,
      });
    } else {
      this.setState({
        name_az: e.target.value,
      });
    }
  };

  addCategory = async (e) => {
    e.preventDefault();
    this.loadingOn();
    if (
      !this.state.name_es.trim().length ||
      !this.state.name_az.trim().length
    ) {
      message.warning("Kateqoriyanın adı boş buraxıla bilməz");
    } else {
      if (this.state.editingId === null) {
        await sualtap
          .post(
            "categories",
            JSON.stringify({
              name_es: this.state.name_es,
              name_az: this.state.name_az,
            })
          )
          .then((res) => {
            message.success("Kateqoriya əlavə edildi");
            this.fetchCategories();
            this.cancelEdit();
          });
      } else {
        await sualtap
          .put(
            `categories/${this.state.editingId}`,
            JSON.stringify({
              id: this.state.editingId,
              name_es: this.state.name_es,
              name_az: this.state.name_az,
            })
          )
          .then((res) => {
            message.success("Kateqoriya düzəliş edildi");
            this.fetchCategories();
            this.cancelEdit();
          });
      }
    }
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Categories</h2>
        </div>
        <div className="b-add-box">
          <div className="b-add-box-left">
            <Table
              columns={columns}
              dataSource={this.state.categories}
              size="middle"
              pagination={{
                pageSize: this.state.per_page,
                current: this.state.page,
                total: this.state.total,
              }}
              onChange={(e) => this.changePage(e)}
            />
          </div>
          <div className="b-add-box-right">
            <form onSubmit={this.addCategory}>
              <input
                placeholder="Category name - es"
                value={this.state.name_es}
                onChange={(e) => this.handleNameChange(e, true)}
                className="ant-input"
                type="text"
              />
              <input
                placeholder="Category name - az"
                value={this.state.name_az}
                onChange={(e) => this.handleNameChange(e, false)}
                className="ant-input mt-10"
                type="text"
              />
              <Button htmlType="submit" type="primary" className="mt-10">
                {this.state.editingId !== null
                  ? "Edit category"
                  : "Add new category"}
                {this.state.loading ? <Spin /> : null}
              </Button>
              {this.state.editingId !== null ? (
                <Button
                  onClick={this.cancelEdit}
                  type="primary"
                  className="mt-10 ml-5"
                >
                  Cancel edit
                </Button>
              ) : null}
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Categories);
