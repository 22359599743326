import React from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { toggleLoading } from "../../../redux/actions";
import { connect } from "react-redux";
import sualtap from "../../../const/api";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Image",
    dataIndex: "images",
    key: "images",
    render: (i) => <img className="table-img" src={i} alt="_img" />,
  },
  {
    title: "Level",
    dataIndex: "level",
    key: "level",
    render: (l) => <p>{l.name}</p>,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Vocabulary extends React.Component {
  state = {
    vocabularies: [],
    total: 10,
    per_page: 5,
    page: 1,
  };

  deleteVocabulary = async (id) => {
    await sualtap
      .delete(`vocabularies/${id}`)
      .then((res) => {
        message.success("Xidmət uğurla silindi");
        this.fetchVocabularies();
      })
      .catch((err) => {
        message.success("Xəta baş verdi. Xidmətin silinməsi mümkün olmadı.");
      });
  };

  fetchVocabularies = async () => {
    const { page, per_page } = this.state;
    this.props.toggleLoading(true);
    await sualtap
      .get(`vocabularies?page=${page}&per_page=${per_page}`)
      .then((res) => {
        this.setState({
          total: res.data.total,
          page: res.data.page,
          per_page: res.data.per_page,
          vocabularies: res.data.data.map((s, index) => {
            return {
              key: index,
              ...s,
              buttons: (
                <>
                <Button type="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ispanadicto.az/vocabulary/${s.id}`}
                  >
                    Open
                  </a>
                </Button>
                  <Button className="ml-5">
                    <Link to={`/vocabulary/edit/${s.id}`}>Edit</Link>
                  </Button>
                  <Popconfirm
                    title="Are you sure delete this vocabulary?"
                    onConfirm={() => this.deleteVocabulary(s.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </>
              ),
            };
          }),
        });
      });
      this.props.toggleLoading(false);
  };

  componentDidMount() {
    this.fetchVocabularies();
  }

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchVocabularies();
    });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Vocabularies</h2>
          <Button type="primary">
            <Link to="/vocabulary/new">Vocabulary əlavə et</Link>
          </Button>
        </div>
        <div className="floater">
          <Table
            dataSource={this.state.vocabularies}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </div>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Vocabulary);
