import React from "react";
import "../../../assets/css/menu.css";
import { connect } from "react-redux";
import history from "./../../../const/history";
import { LogoutOutlined } from "@ant-design/icons";
import sualtap from "../../../const/api";

const TopMenu = (props) => {
  const goBack = () => {
    history.goBack();
  };

  const logOut = async () => {
    localStorage.removeItem("user");
    await sualtap.post("auth/logout");
    window.location.reload();
  };

  return (
    <div className="topMenu">
      <div className="flex">
        <p className="goBack" onClick={goBack}>
          Geri
        </p>
        <h3>{props.user?.first_name + " " + props.user?.last_name}</h3>
      </div>
      <p className="goBack margin0" onClick={logOut}>
        <LogoutOutlined />
      </p>
    </div>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    user: user.data.user,
  };
};

export default connect(mapStateToProps)(TopMenu);
