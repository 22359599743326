import React from "react";
import { Table, Button, Popconfirm, InputNumber, message } from "antd";
import { Link } from "react-router-dom";
import { toggleLoading } from "../../../redux/actions";
import { connect } from "react-redux";
import sualtap from "../../../const/api";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (i) => <img className="table-img" src={i} alt="_img" />,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Services extends React.Component {
  state = {
    services: [],
    page: 1,
    per_page: 5,
    total: 10,
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchServices();
    });
  };

  deleteSevice = async (id) => {
    await sualtap
      .delete(`services/${id}`)
      .then((res) => {
        message.success("Xidmət uğurla silindi");
        this.fetchServices();
      })
      .catch((err) => {
        message.success("Xəta baş verdi. Xidmətin silinməsi mümkün olmadı.");
      });
  };

  handlePriorityChange = async (e, index) => {
    const val = e.target.value;
    if (this.state.services[index].priority !== e.target.value) {
      sualtap
        .get(`services/${this.state.services[index].id}/show`)
        .then((res) => {
          sualtap
            .post(`services/${this.state.services[index].id}/update`, {
              ...res.data,
              image: null,
              priority: val,
            })
            .then((res) => {
              console.log(res.data);
            });
        });
    }
  };

  fetchServices = async () => {
    const { page, per_page } = this.state;
    this.props.toggleLoading(true);
    await sualtap
      .get(`services?page=${page}&per_page=${per_page}`)
      .then((res) => {
        this.setState({
          total: res.data.total,
          page: res.data.page,
          per_page: res.data.per_page,
          services: res.data.data.map((s, index) => {
            return {
              key: index,
              ...s,
              priorityField: (
                <InputNumber
                  onBlur={(e) => this.handlePriorityChange(e, index)}
                  defaultValue={s.priority}
                />
              ),
              buttons: (
                <>
                  <Button type="primary">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://ispanadicto.az/services/${s.id}`}
                    >
                      Open
                    </a>
                  </Button>
                  <Button className="ml-5">
                    <Link to={`/services/edit/${s.id}`}>Edit</Link>
                  </Button>
                  <Popconfirm
                    title="Are you sure delete this service?"
                    onConfirm={() => this.deleteSevice(s.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5" danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </>
              ),
            };
          }),
        });
      });
    this.props.toggleLoading(false);
  };

  componentDidMount() {
    this.fetchServices();
  }

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Services</h2>
          <Button type="primary">
            <Link to="/services/new">Xidmət əlavə et</Link>
          </Button>
        </div>
        <div className="floater">
          <Table
            dataSource={this.state.services}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </div>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Services);
