import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, Input, Form, Switch } from "antd";

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item name={dataIndex}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class ConjugationTable extends React.Component {
  state = {
    columns: [],
    dataSource: [
      { name: "yo", key: 1 },
      { name: "tú", key: 2 },
      { name: "él/ella/Ud.", key: 3 },
      { name: "nosotros", key: 4 },
      { name: "vosotros", key: 5 },
      { name: "ellos/ellas/Uds.", key: 6 },
    ],
  };

  componentDidMount() {
    const data = this.props.data.data;
    let dataSource = [];
    Object.keys(data).forEach((k, index) => {
      let obj = {};
      obj["name"] = k;
      obj["key"] = ++index;
      this.props.data.headers.forEach((h, hindex) => {
        obj[h] = data[k][hindex];
      });
      dataSource.push(obj);
    });
    let columns = [{ title: "", dataIndex: "name", key: "0", editable: false }];
    this.props.data.headers.forEach((h, index) => {
      columns.push({
        title: h,
        dataIndex: h,
        key: (index + 1).toString(),
        editable: true,
      });
    });
    this.setState({
      dataSource,
      columns,
    });
  }

  formMyData = (data) => {
    let obj = {};
    const headers = this.props.data.headers;
    data.forEach((d) => {
      obj[d.name] = headers.map((h) => {
        return d[h] ? d[h] : "-";
      });
    });
    return obj;
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
    this.props.saveData(this.formMyData(newData), this.props.name);
  };

  handleHideChange = (e) => {
    this.props.handleHide(e, this.props.name);
  };

  render() {
    const { dataSource, columns } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns_a = columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          key: col.key,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <div>
        <Switch
          className="mb-10"
          checked={this.props.data.hide}
          onChange={this.handleHideChange}
          checkedChildren={<p>İstifadəçilərdən gizlət</p>}
          unCheckedChildren={<p>İstifadəçilərə göstər</p>}
        ></Switch>
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          pagination={false}
          dataSource={dataSource}
          columns={columns_a}
        />
      </div>
    );
  }
}

export default ConjugationTable;
