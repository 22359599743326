import React, { Component } from "react";
import { Menu, Button } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./style/LeftMenu.css";
import {
  FundOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SnippetsOutlined,
  UserOutlined,
  UpCircleOutlined,
  AimOutlined,
  PicCenterOutlined,
  QuestionCircleOutlined,
  MailOutlined,
  BookOutlined
} from "@ant-design/icons";
const { SubMenu } = Menu;

class LeftMenu extends Component {
  state = {
    mode: "inline",
    theme: "light",
    collapsed: false,
    openKeys: [],
  };
  rootSubmenuKeys = ["sub1", "sub2"];

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    return (
      <div
        style={{
          col: 2,
          float: "left",
          width: this.state.collapsed ? 80 : 300,
          minHeight: "100vh",
          backgroundColor: "white",
          zIndex: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: this.state.collapsed ? "center" : "flex-end",
            padding: this.state.collapsed ? "15px 0px" : "15px 10px 15px 0px",
          }}
        >
          <Button type="primary" onClick={this.toggleCollapsed}>
            {this.state.collapsed ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined />
            )}
          </Button>
        </div>
        <Menu
          openKeys={this.state.openKeys}
          inlineCollapsed={this.state.collapsed}
          mode={this.state.mode}
          theme={this.state.theme}
          onOpenChange={this.onOpenChange}
        >
          <Menu.Item key="2">
            <Link to="/services">
              <UpCircleOutlined />
              <span>Services</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/articles">
              <BookOutlined />
              <span>Articles</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/texts">
              <PicCenterOutlined />
              <span>Texts</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="5">
            <Link to="/vocabulary">
              <QuestionCircleOutlined />
              <span>Vocabulary</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/translation">
              <SnippetsOutlined />
              <span>Translation</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/words">
              <AimOutlined />
              <span>Words</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="8">
            <Link to="/sentences">
              <FundOutlined />
              <span>Sentences</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/contacts">
              <MailOutlined />
              <span>Messages</span>
            </Link>
          </Menu.Item>
          <SubMenu key="10" icon={<UserOutlined />} title="Other">
            <Menu.Item key="11">
              <Link to="/users">
                <span>Users</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="12">
              <Link to="/static">
                <span>Static</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="13">
              <Link to="/categories">
                <span>Categories</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="14">
              <Link to="/types">
                <span>Types</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="15">
              <Link to="/word-widgets">
                <span>Word Widgets</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user: user.data.role,
  };
};
export default connect(mapStateToProps)(LeftMenu);
