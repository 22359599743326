import React from "react";
import { Table, Popconfirm, Button, Input, message } from "antd";
import sualtap from "../../../const/api";
import { toggleLoading } from "./../../../redux/actions";
import { connect } from "react-redux";
const { Search } = Input;

const word_columns = [
  {
    title: "Günün sözü",
    dataIndex: "word_of_day",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Ən populyar söz",
    dataIndex: "popular_word",
    render: (w) => <p>{w?.word}</p>,
  },
];

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Word",
    dataIndex: "word",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Əməliyyatlar",
    render: (a) => a.buttons,
  },
];

class WordWidgets extends React.Component {
  state = {
    info: [],
    words: [],
    loading: false,
    total: 10000,
    page: 1,
    per_page: 10,
    keyword: "",
  };

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  cancel() {
    console.log("cancelled");
  }

  setAsMostSearchedWord = async (e) => {
    await sualtap.put("info", { popular_word_id: e }).then((res) => {
      this.getInfo().then((res) => {
        message.success("Düzəliş uğurlu oldu.");
      });
    });
  };

  setWordOfTheDay = async (e) => {
    await sualtap.put("info", { word_of_day_id: e }).then((res) => {
      this.getInfo().then((res) => {
        message.success("Düzəliş uğurlu oldu.");
      });
    });
  };

  fetchWords = async () => {
    const search =
      this.state.keyword !== null ? `&word=${this.state.keyword}` : "";
    this.setState({ search_loading: true });
    await sualtap
      .get(
        `words?page=${this.state.page}&per_page=${this.state.per_page}${search}`
      )
      .then((res) => {
        this.setState({
          total: res.data.total,
          page: res.data.current_page,
          search_loading: false,
          words: res.data.data.map((re) => {
            return {
              key: re.id,
              ...re,
              buttons: (
                <>
                  <Popconfirm
                    title="Are you sure you want to set this word as the most searched word?"
                    onConfirm={() => this.setAsMostSearchedWord(re.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5">Set as most searched word</Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Are you sure you want to set this word as word of the day?"
                    onConfirm={() => this.setWordOfTheDay(re.id)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="ml-5" danger>
                      Set as word of the day
                    </Button>
                  </Popconfirm>
                </>
              ),
            };
          }),
        });
      });
  };

  getInfo = async () => {
    await sualtap.get("info").then((res) => {
      this.setState({ info: [{ ...res.data.data, key: 1 }] });
    });
  };

  async componentDidMount() {
    this.props.toggleLoading(true);
    await this.getInfo();
    await this.fetchWords(null);
    this.props.toggleLoading(false);
  }

  handleWordChange = (e) => {
    this.setState({
      word: e.target.value,
    });
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchWords();
    });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Words widgets</h2>
        </div>
        <div className="w-100 fl">
          <Table
            columns={word_columns}
            dataSource={this.state.info}
            className="mb-10"
            pagination={false}
          />
        </div>
        <div className="w-100 fl">
          <Search
            placeholder="Search a word"
            loading={this.state.search_loading}
            disabled={this.state.search_loading}
            value={this.state.keyword}
            onChange={(e) => this.handleKeywordChange(e.target.value)}
            onSearch={this.fetchWords}
            className="mb-10 search-input"
            enterButton
          />
        </div>
        <div className="w-100 fl">
          <Table
            columns={columns}
            dataSource={this.state.words}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
            size="middle"
          />
        </div>
      </>
    );
  }
}

export default connect(null, {toggleLoading})(WordWidgets);
