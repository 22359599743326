import React from "react";
import sualtap from "../../../const/api";
import {
  Button,
  Switch,
  Table,
  Popconfirm,
  Row,
  Col,
  Modal,
  message,
  Spin,
  Input,
  notification,
} from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { connect } from "react-redux";
import { toggleLoading } from "../../../redux/actions";
const { Search } = Input;

const { Option } = Select;

const current_sentences = [
  {
    title: "Sentence - es",
    dataIndex: "sentence_es",
    key: "id",
  },
  {
    title: "Sentence - az",
    dataIndex: "sentence_az",
  },
  {
    title: "Əməliyyatlar",
    render: (a) => a.buttons,
  },
];

const columns_sentences = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Sentence - es",
    dataIndex: "sentence_es",
  },
  {
    title: "Sentence - az",
    dataIndex: "sentence_az",
  },
  {
    title: "Əməliyyatlar",
    render: (a) => a.buttons,
  },
];
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (c) => (
      <p>
        {c?.name_az}-{c?.name_es}
      </p>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (t) => (
      <p>
        {t?.name_az}-{t?.name_es}
      </p>
    ),
  },
  {
    title: "Azerbaijani",
    dataIndex: "azerbaijani",
    key: "azerbaijani",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Spanish",
    dataIndex: "spanish",
    key: "spanish",
    render: (w) => <p>{w?.word}</p>,
  },
  {
    title: "Main",
    dataIndex: "additional",
    key: "additional",
    render: (a) => <p>{a === "0" ? "Main" : "Additional"}</p>,
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Translation extends React.Component {
  state = {
    visible: false,
    categories: [],
    types: [],
    translations: [],
    per_page: 10,
    sentences_per_page: 10,
    page: 1,
    sentences_page: 1,
    total: 0,
    sentences_total: 0,
    editingId: null,
    azerbaijaniWords: [],
    spanishWords: [],
    loading: false,
    search_loading: false,
    keyword: "",
    keyword_es: "",
    keyword_az: "",
    sentences: [],
    sentence_keyword: "",
    sentence_search_loading: false,
    sentence_visible: false,
    current_sentences: [],
    editing_of: null,
    translation: {
      azerbaijani: null,
      spanish: null,
      type_id: null,
      category_id: null,
      additional: true,
      main: false,
    },
  };

  //Sentence
  handleSentenceKeywordChange = (sentence_keyword) => {
    this.setState({ sentence_keyword });
  };

  confirm = async (id) => {
    this.setState({ loading: true });
    await sualtap.delete(`translations/${id}`).then((res) => {
      notification.info({
        message: "Successfull",
        description: "The translation is successfully deleted",
        placement: "topRight",
        icon: <SmileOutlined />,
      });
      this.getTranslations();
    });
  };

  cancel() {
    console.log("cancelled");
  }

  fetchSentences = async () => {
    const search =
      this.state.sentence_keyword !== null
        ? `&key_word=${this.state.sentence_keyword.trim()}`
        : "";
    this.setState({ sentence_search_loading: true });
    await sualtap
      .get(
        `sentences?page=${this.state.sentences_page}&per_page=${this.state.sentences_per_page}${search}`
      )
      .then((res) => {
        this.setState({
          sentence_search_loading: false,
          sentences_page: res.data.page,
          sentences_total: res.data.total,
          sentences: res.data.data.map((re) => {
            return {
              key: re.id,
              ...re,
              buttons: (
                <>
                  <Button
                    onClick={() => this.addToTranslation(re)}
                    type="primary"
                  >
                    Add to translation
                  </Button>
                </>
              ),
            };
          }),
        });
      });
  };
  //Sentence ends

  handleKeywordChange = (keyword) => {
    this.setState({ keyword });
  };

  setModalVisible = (visible) => {
    this.setState({ visible });
  };

  deleteSentence = async (tr, id, isnew) => {
    if(!isnew){
      await sualtap
      .delete(`translations/${tr}/sentence/${id}`)
      .then((res) => {
        message.success("Cümlə uğurla silindi.");
        this.setState({
          current_sentences: [
            ...this.state.current_sentences.filter((cs) => cs.id !== id),
          ],
        });
      })
      .catch((er) => {
        message.warning("Silinmə mümkün olmadı");
      });
    }
    else {
      this.setState({
        current_sentences: [
          ...this.state.current_sentences.filter((cs) => cs.id !== id),
        ],
      });
    }
  };

  addToTranslation = (re) => {
    if (this.state.current_sentences.length < 3) {
      let ids = this.state.current_sentences.map(cs=>cs.id);
      if(!ids.includes(re.id)){
        let ob = {
          new: true,
          ...re,
          buttons: (
            <Popconfirm
              title="Are you sure delete this sentence?"
              onConfirm={() => this.deleteSentence(null, re.id, true)}
              onCancel={this.cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className="ml-5" danger>
                Delete
              </Button>
            </Popconfirm>
          ),
  
        };
        let current_sentences = [...this.state.current_sentences];
        current_sentences.push(ob);
        this.setState({ current_sentences });  
      }
      else {
        message.warning("Bu cümlə artıq əlavə olunub.");
      }
    } else {
      message.warning("Maksimum 3 cümlə əlavə edə bilərsiniz.");
    }
  };

  saveSentences = async () => {
    const sentences_ids = this.state.current_sentences
      .filter((cs) => cs.new)
      .map((cs) => cs.id);
    await sualtap
      .post(
        `translations/${this.state.editing_of}/sentence`,
        JSON.stringify({ sentences_ids })
      )
      .then((res) => {
        notification.info({
          message: "Successfull",
          description: "The sentences are successfully added",
          placement: "topRight",
          icon: <SmileOutlined />,
        });
        this.setSentenceModalVisible(false, null);
      })
      .catch((err) => {
        message.warning("Some error occured");
      });
  };

  setSentenceModalVisible = async (sentence_visible, id) => {
    this.setState({ sentence_visible, editing_of: id });
    if (sentence_visible) {
      await sualtap.get(`translations/${id}`).then((res) => {
        this.setState({
          current_sentences: res.data.sentences.map((re, index) => {
            return {
              key: index,
              new: false,
              ...re,
              buttons: (
                <Popconfirm
                  title="Are you sure delete this sentence?"
                  onConfirm={() => this.deleteSentence(id, re.id, false)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="ml-5" danger>
                    Delete
                  </Button>
                </Popconfirm>
              ),
            };
          }),
        });
      });
      this.fetchSentences();
    }
  };

  async componentDidMount() {
    this.props.toggleLoading(true);
    await this.getTranslations();
    this.getCategories();
    this.props.toggleLoading(false);
  }

  onTranslationChange = (e, key) => {
    let translation = { ...this.state.translation };
    translation[key] = e;
    this.setState({ translation }, () => {
      if (key === "category_id") {
        this.getTypes();
      }
    });
  };

  saveTranslation = async () => {
    const {
      azerbaijani,
      spanish,
      type_id,
      category_id,
      additional,
    } = this.state.translation;
    if (
      azerbaijani != null &&
      spanish != null &&
      type_id != null &&
      category_id != null &&
      additional != null
    ) {
      this.setState({ loading: true });
      if (this.state.editingId != null) {
        await sualtap
          .put(`translations/${this.state.editingId}`, {
            azerbaijani,
            spanish,
            type_id,
            category_id,
            additional,
          })
          .then((res) => {
            message.success("Düzəliş uğurlu oldu");
            this.setState({ loading: false });
            this.setModalVisible(false);
            this.getTranslations();
          });
      } else {
        await sualtap
          .post(`translations`, {
            azerbaijani,
            spanish,
            type_id,
            category_id,
            additional,
          })
          .then((res) => {
            message.success("Tərcümə uğurla əlavə edildi");
            this.setState({ loading: false });
            this.setModalVisible(false);
            this.getTranslations();
          });
      }
    } else {
      message.warning("Məlumatlar tam olmalıdır.");
    }
  };

  handleAdditionalChange = () => {
    let translation = { ...this.state.translation };
    translation.additional = !this.state.translation.additional;
    translation.main = !translation.additional;
    this.setState({ translation });
  };

  onChange = (e) => {
    console.log(e);
  };

  onSearchWord = async (e, type) => {
    if (e !== "") {
      if (type === "es") {
        this.setState({ keyword_es: e });
      } else {
        this.setState({ keyword_az: e });
      }
      await sualtap
        .get(`words?compact=1&type=${type}&word=${e}`)
        .then((res) => {
          switch (type) {
            case "es":
              if (e === this.state.keyword_es) {
                this.setState({ spanishWords: res.data });
              }
              break;
            case "az":
              if (e === this.state.keyword_az) {
                this.setState({ azerbaijaniWords: res.data });
              }
              break;
            default:
              break;
          }
        });
    }
  };

  getTypes = async () => {
    await sualtap
      .get(`types?category_id=${this.state.translation.category_id}`)
      .then((res) => {
        this.setState({ types: res.data.data });
      });
  };

  editTranslation = (visible, tr) => {
    let translation = {
      spanish: tr.spanish.id,
      azerbaijani: tr.azerbaijani.id,
      category_id: tr.category_id,
      type_id: tr.type_id,
      additional: tr.additional === "0" ? true : false,
      main: tr.additional !== "0" ? true : false,
    };
    this.setState(
      {
        visible,
        editingId: tr.id,
        translation,
        types: [],
        azerbaijaniWords: [tr.azerbaijani],
        spanishWords: [tr.spanish],
      },
      () => {
        this.getTypes();
      }
    );
  };

  addNewTranslation = () => {
    const translation = {
      azerbaijani: null,
      spanish: null,
      type_id: null,
      category_id: null,
      additional: true,
      main: false,
    };
    this.setState({ visible: true, translation, editingId: null });
  };

  getTranslations = async () => {
    const search =
      this.state.keyword !== null ? `&key_word=${this.state.keyword}` : "";
    this.setState({ search_loading: true });
    await sualtap
      .get(
        `translations?page=${this.state.page}&per_page=${this.state.per_page}${search}`
      )
      .then((res) => {
        res.data.data.forEach((d, index) => {
          d.key = index;
          d.buttons = (
            <>
              <Button
                onClick={() => this.setSentenceModalVisible(true, d.id)}
                className="ml-10"
              >
                Modify sentences
              </Button>
              <Button
                onClick={() => this.editTranslation(true, d)}
                className="ml-10"
              >
                Edit
              </Button>
              <Popconfirm
                title="Are you sure delete this translation?"
                onConfirm={() => this.confirm(d.id)}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button className="ml-5" danger>
                  Delete
                </Button>
              </Popconfirm>
            </>
          );
        });
        this.setState({
          translations: res.data.data,
          total: res.data.total,
          page: res.data.current_page,
          search_loading: false,
        });
      });
  };

  getCategories = async () => {
    await sualtap.get("categories").then((res) => {
      this.setState({ categories: res.data.data });
    });
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.getTranslations();
    });
  };

  changePageSentences = (e) => {
    this.setState(
      { sentences_page: e.current, sentences_per_page: e.pageSize },
      () => {
        this.fetchSentences();
      }
    );
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Translations</h2>
          <Button type="primary" onClick={() => this.addNewTranslation()}>
            Tərcümə əlavə et
          </Button>
        </div>

        <div className="floater">
          <Search
            placeholder="Search a translation"
            loading={this.state.search_loading}
            disabled={this.state.search_loading}
            value={this.state.keyword}
            onChange={(e) => this.handleKeywordChange(e.target.value)}
            onSearch={this.getTranslations}
            className="mb-10"
            enterButton
          />
          <Table
            dataSource={this.state.translations}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </div>
        <Modal
          title="Modify sentences"
          visible={this.state.sentence_visible}
          style={{ top: 10 }}
          width={920}
          onOk={this.saveSentences}
          onCancel={() => this.setSentenceModalVisible(false, null)}
        >
          <p>Mövcud cümlələr</p>
          <Table
            columns={current_sentences}
            dataSource={this.state.current_sentences}
            pagination={false}
            className="mb-10"
          />
          <Search
            placeholder="Search a sentence"
            loading={this.state.sentence_search_loading}
            disabled={this.state.sentence_search_loading}
            value={this.state.sentence_keyword}
            onChange={(e) => this.handleSentenceKeywordChange(e.target.value)}
            onSearch={this.fetchSentences}
            className="mb-10"
            enterButton
          />
          <Table
            columns={columns_sentences}
            dataSource={this.state.sentences}
            pagination={{
              pageSize: this.state.sentences_per_page,
              total: this.state.sentences_total,
              current_page: this.state.sentences_page,
            }}
            onChange={this.changePageSentences}
            size="middle"
          />
        </Modal>
        <Modal
          title="Translation"
          visible={this.state.visible}
          footer={null}
          onCancel={() => this.setModalVisible(false)}
        >
          <Row gutter={16} className="mb-10">
            <Col span={12}>
              <Select
                className="fl w-100"
                showSearch
                placeholder="Select a category"
                optionFilterProp="children"
                value={this.state.translation.category_id}
                onChange={(e) => this.onTranslationChange(e, "category_id")}
              >
                {this.state.categories.map((t, index) => {
                  return (
                    <Option key={index} value={t.id}>
                      {t.name_az} | {t.name_es}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={12}>
              <Select
                className="fl w-100"
                showSearch
                placeholder="Select a type"
                optionFilterProp="children"
                value={this.state.translation.type_id}
                onChange={(e) => this.onTranslationChange(e, "type_id")}
              >
                {this.state.types.map((t, index) => {
                  return (
                    <Option key={index} value={t.id}>
                      {t.name_az} | {t.name_es}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={24}>
              <Select
                className="fl w-100 mt-10"
                showSearch
                placeholder="Select an azerbaijani word"
                optionFilterProp="children"
                value={this.state.translation.azerbaijani}
                onChange={(e) => this.onTranslationChange(e, "azerbaijani")}
                onSearch={(e) => this.onSearchWord(e, "az")}
              >
                {this.state.azerbaijaniWords.map((t, index) => {
                  return (
                    <Option key={index} value={t.id}>
                      {t.word}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={24}>
              <Select
                className="fl w-100 mt-10"
                showSearch
                placeholder="Select a spanish word"
                optionFilterProp="children"
                value={this.state.translation.spanish}
                onChange={(e) => this.onTranslationChange(e, "spanish")}
                onSearch={(e) => this.onSearchWord(e, "es")}
              >
                {this.state.spanishWords.map((t, index) => {
                  return (
                    <Option key={index} value={t.id}>
                      {t.word}
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Row className="mt-10 mb-10" gutter={16}>
            <Col span={24}>
              <Switch
                className="fr"
                checkedChildren="Main"
                unCheckedChildren="Additional"
                checked={this.state.translations.main}
                onChange={this.handleAdditionalChange}
              />
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                className="mt-10"
                onClick={this.saveTranslation}
              >
                Yadda saxla
                {this.state.loading ? <Spin /> : null}
              </Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Translation);
