import React, { useEffect } from "react";
// import { Statistic, Row, Col, Card } from "antd";
// import { ArrowUpOutlined } from "@ant-design/icons";
// import sualtap from "../../const/api";
import { connect } from "react-redux";
import { loadingOff, loadingOn } from "./../../redux/actions";

const Statistics = (props) => {
  // const [stats] = useState({});

  useEffect(() => {
    // const fetchStats = async () => {
    //   props.loadingOn();
    //   await sualtap.get("info/stats").then(res => {
    //     console.log(res.data);
    //     setStats(res.data);
    //   });
    //   props.loadingOff();
    // }
    // fetchStats();
  }, [props]);

  return <p></p>;
  // (
  //   <Row gutter={[16, 16]}>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Tək suallar"
  //           value={stats.nhn}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Mövzular"
  //           value={stats.theme}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Oyun növləri"
  //           value={stats.games}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Turnirlər"
  //           value={stats.tournaments}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Hazır paketlər"
  //           value={stats.packages}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Müəlliflər"
  //           value={stats.authors}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Təşkilatlar"
  //           value={stats.organizations}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Xəmsə raundu"
  //           value={stats.xamsa}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //     <Col span={6}>
  //       <Card>
  //         <Statistic
  //           title="Breyn rinq"
  //           value={stats.br}
  //           valueStyle={{ color: "#721FB5" }}
  //           prefix={<ArrowUpOutlined />}
  //         />
  //       </Card>
  //     </Col>
  //   </Row>
  // );
};

export default connect(null, { loadingOn, loadingOff })(Statistics);
