import React, { useState } from "react";
import { Row, Col, Alert, message, Popconfirm, Spin } from "antd";
import { Upload, Button } from "antd";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons";
import speaker from "./../speaker.svg";
import sualtap from "../../../../const/api";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 15, color: "white" }} spin />
);

const Voice = ({ voices, id, fetchWord }) => {
  const [file, setFile] = useState(null);
  const [fileList, setFilelist] = useState([]);
  const [loading, setLoading] = useState(false);

  let audio = new Audio(voices && voices.es ? voices.es : null);

  const start = () => {
    audio.play();
  };

  const props = {
    multiple: false,
    beforeUpload: (f) => {
      setFile(f);
      setFilelist([f]);
      return false;
    },
    fileList: fileList,
  };

  const saveVoice = () => {
    const data = new FormData();
    data.append("es", file);
    setLoading(true);
    sualtap.post(`words/${id}/voice`, data).then((res) => {
      message.success("Səs əlavə olundu");
      setLoading(false);
      setFilelist([]);
      setFile(null);
      fetchWord();
    });
  };

  const deleteVoice = () => {
    setLoading(true);
    sualtap.post(`words/${id}/voice/reset`, { reset: "es" }).then((res) => {
      message.success("Səs uğurla silindi");
      setLoading(false);
      setFilelist([]);
      setFile(null);
      fetchWord();
    });
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Col span={24}>
            {voices && voices.es ? (
              <Alert message="Bu sözə səslənmə əlavə olunub" type="success" />
            ) : (
              <Alert
                message="Bu sözə səslənmə əlavə olunmayıb"
                type="warning"
              />
            )}
          </Col>
          {voices && voices.es ? (
            <Col span={24}>
              <img
                src={speaker}
                alt="_speaker_icon"
                className="pointer"
                style={{ width: "100px", height: "100px" }}
                onClick={start}
              />
            </Col>
          ) : null}
        </Col>
        <Col span={12}>
          <Col span={8}>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Yeni səslənmə yüklə</Button>
            </Upload>
          </Col>
          <Col span={12}>
            {file && (
              <Button type="primary" onClick={saveVoice}>
                Səsi yadda saxla
                {loading ? <Spin indicator={antIcon} /> : null}
              </Button>
            )}
            {voices && voices.es && !file ? (
              <Popconfirm
                title="Səsi silmək istədiyinzdən əminsiniz?"
                onConfirm={deleteVoice}
              >
                <Button type="danger" className="ml-10">
                  Mövcud səslənməni sil
                  {loading ? <Spin indicator={antIcon} /> : null}
                </Button>
              </Popconfirm>
            ) : null}
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default Voice;
