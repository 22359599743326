import React from "react";
import Loader from "./Elements/Loader";
import { connect } from "react-redux";
import LeftMenu from "./Layout/LeftMenu/LeftMenu";
import TopMenu from "./Layout/TopMenu/TopMenu";
import { Route } from "react-router-dom";
import Statistics from "./Pages/Statistics";
import Login from "./Layout/Login/Login";
import { logUserIn, toggleLoading } from "./../redux/actions/index";
import Articles from "./Pages/Articles/Articles";
import Article from "./Pages/Articles/Article";
import Services from "./Pages/Services/Services";
import Service from "./Pages/Services/Service";
import Vocabulary from "./Pages/Vocabulary/Vocabulary";
import Voc from "./Pages/Vocabulary/Voc";
import Texts from "./Pages/Texts/Texts";
import Text from "./Pages/Texts/Text";
import Translation from "./Pages/Translation/Translation";
import Types from "./Pages/Static/Types";
import Categories from "./Pages/Static/Categories";
import Conjugation from "./Pages/Conjugation/Conjugation";
import Words from "./Pages/Words/Words";
import Sentences from "./Pages/Sentences/Sentences";
import Static from "./Pages/Static/Static";
import Users from "./Pages/Static/Users";
import WordWidgets from "./Pages/Static/WordWidgets";
import Word from "./Pages/Words/Word";
import Contacts from "./Pages/Contacts";

class App extends React.Component {
  componentDidMount() {
    if (localStorage.getItem("access_token")) {
      this.props.logUserIn();
    }
  }

  render() {
    return (
      <>
        {this.props.isLoading > 0 ? <Loader /> : null}
        {this.props.isLoggedIn ? (
          <>
            <div id="page">
              <LeftMenu toggleMenu={this.toggleMenu} />
              <div id="pageRight">
                <TopMenu />
                <Route path="/" exact>
                  <Statistics />
                </Route>
                <Route exact path="/articles">
                  <Articles />
                </Route>
                <Route exact path="/articles/new">
                  <Article />
                </Route>
                <Route exact path="/texts">
                  <Texts />
                </Route>
                <Route exact path="/texts/new">
                  <Text />
                </Route>
                <Route exact path="/vocabulary">
                  <Vocabulary />
                </Route>
                <Route exact path="/vocabulary/new">
                  <Voc />
                </Route>
                <Route exact path="/services">
                  <Services />
                </Route>
                <Route exact path="/services/new">
                  <Service />
                </Route>
                <Route
                  exact
                  path="/services/edit/:id"
                  render={({ match }) => <Service params={match.params} />}
                />
                <Route
                  exact
                  path="/articles/edit/:id"
                  render={({ match }) => <Article params={match.params} />}
                />
                <Route
                  exact
                  path="/vocabulary/edit/:id"
                  render={({ match }) => <Voc params={match.params} />}
                />
                <Route
                  exact
                  path="/texts/edit/:id"
                  render={({ match }) => <Text params={match.params} />}
                />
                <Route
                  exact
                  path="/word/:id"
                  render={({ match }) => <Word params={match.params} />}
                />
                <Route exact path="/translation">
                  <Translation />
                </Route>
                <Route exact path="/words">
                  <Words />
                </Route>
                <Route exact path="/users">
                  <Users />
                </Route>
                <Route exact path="/static">
                  <Static />
                </Route>
                <Route exact path="/sentences">
                  <Sentences />
                </Route>
                <Route exact path="/categories">
                  <Categories />
                </Route>
                <Route exact path="/types">
                  <Types />
                </Route>
                <Route exact path="/word-widgets">
                  <WordWidgets />
                </Route>
                <Route exact path="/conjugation">
                  <Conjugation />
                </Route>
                <Route exact path="/contacts">
                  <Contacts />
                </Route>
              </div>
            </div>
          </>
        ) : (
          <Login logIn={this.toggleLoggedIn} />
        )}
      </>
    );
  }
}
const mapStateToProps = ({ user, loader }) => {
  return {
    isLoggedIn: user.isLoggedIn,
    // isLoggedIn: true,
    isLoading: loader,
    user: user.data.role,
  };
};

export default connect(mapStateToProps, { logUserIn, toggleLoading })(App);
