import React, { Component } from "react";
import { Input, Col, Row, Tabs, Button, message, Spin } from "antd";
import Uploader from "./../../Elements/Uploader";
import sualtap from "../../../const/api";
import { connect } from "react-redux";
import history from "./../../../const/history";
import { toggleLoading } from "./../../../redux/actions";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const { TabPane } = Tabs;

class Article extends Component {
  state = {
    es: {
      title: "",
      description: "",
    },
    az: {
      title: "",
      description: "",
    },
    images: [],
    prevImg: [],
    loading: false,
    editMode: false,
  };

  componentDidMount = async () => {
    if (this.props.params && this.props.params.id) {
      this.props.toggleLoading(true);
      await sualtap.get(`blog/${this.props.params.id}/show`).then((res) => {
        const images = res.data.images.map((i, index) => {
          return {
            uid: `-${index}`,
            id: i.id,
            name: "image.png",
            status: "done",
            url: i.url,
          };
        });
        let obj = {
          editMode: true,
          es: {
            title: res.data.title_es,
            description: res.data.description_es,
          },
          az: {
            title: res.data.title_az,
            description: res.data.description_az,
          },
          prevIds: res.data.images.map((i) => i.id),
          images,
          prevImg: images,
        };
        this.setState({ ...obj });
      });
      this.props.toggleLoading(false);
    }
  };

  handleTextChange = (value, lang, part) => {
    let l = { ...this.state };
    l[lang][part] = value;
    this.setState({
      ...l,
    });
  };

  setImage = (images) => {
    this.setState({ images: images.map((i) => (i.id ? i : i.originFileObj)) });
  };

  isNullOrEmpty(str) {
    return str == null || !str.length;
  }

  validateArticle() {
    const { az, es, images } = this.state;
    if (
      this.isNullOrEmpty(az.description) ||
      this.isNullOrEmpty(es.description) ||
      this.isNullOrEmpty(az.title) ||
      this.isNullOrEmpty(es.title) ||
      images.length === 0
    ) {
      return false;
    }
    return true;
  }

  saveArticle = async () => {
    if (this.validateArticle()) {
      const { az, es, images } = this.state;
      this.setState({ loading: true });
      if (!this.state.editMode) {
        var data = new FormData();
        data.append("description_az", az.description);
        data.append("description_es", es.description);
        data.append("title_az", az.title);
        data.append("title_es", es.title);
        images.forEach((i) => {
          data.append("image[]", i);
        });
        await sualtap
          .post("blog", data)
          .then((res) => {
            message.success("Məqalə əlavə olundu");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      } else {
        const imageData = new FormData();
        let needStore = false;
        const currentImgs = [];
        images.forEach((im) => {
          if (!im.id) {
            imageData.append("image[]", im);
            needStore = true;
          } else {
            currentImgs.push(im.id);
          }
        });
        this.state.prevIds.forEach((p) => {
          if (!currentImgs.includes(p)) {
            sualtap.delete(`blog/image/${p}`).then((res) => {
              console.log(res);
            });
          }
        });
        if (needStore) {
          await sualtap
            .post(`blog/${this.props.params.id}/image`, imageData)
            .then((res) => {
              console.log(res);
            });
        }
        let putParams = {
          description_az: az.description,
          description_es: es.description,
          title_az: az.title,
          title_es: az.title,
        };
        await sualtap
          .put(`blog/${this.props.params.id}`, putParams)
          .then((res) => {
            message.success("Düzəliş edildi");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
        history.push("/articles");
      }
    } else {
      message.warning("Bütün məlumatlar doldurulmalıdır.");
    }
  };

  render() {
    return (
      <div className="App">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Azerbaijani" key="1">
            <Row gutter={16}>
              <Col span={8}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.az.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "az", "title")
                  }
                />
              </Col>
              <Col span={16}>
                <CKEditor
                  id="azEditor"
                  editor={ClassicEditor}
                  data={this.state.az.description}
                  onChange={(event, editor) => {
                    this.handleTextChange(
                      editor.getData(),
                      "az",
                      "description"
                    );
                  }}
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "https://ispanadicto.site/api/editor/image?token=" +
                        localStorage.getItem("access_token"),
                      options: {
                        resourceType: "Images",
                      },
                    },
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Spanish" key="2">
            <Row gutter={16}>
              <Col span={8}>
                <Input
                  placeholder="Title"
                  className="mb-10"
                  value={this.state.es.title}
                  onChange={(e) =>
                    this.handleTextChange(e.target.value, "es", "title")
                  }
                />
              </Col>
              <Col span={16}>
                <CKEditor
                  id="esEditor"
                  editor={ClassicEditor}
                  data={this.state.es.description}
                  config={{
                    ckfinder: {
                      uploadUrl:
                        "https://ispanadicto.site/api/editor/image?token=" +
                        localStorage.getItem("access_token"),
                      options: {
                        resourceType: "Images",
                      },
                    },
                  }}
                  onChange={(event, editor) => {
                    this.handleTextChange(
                      editor.getData(),
                      "es",
                      "description"
                    );
                  }}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
        <div className="mt-10">
          <Uploader
            prevImg={this.state.prevImg}
            setFile={this.setImage}
            multiple={true}
            count={8}
          />
        </div>
        <Button
          onClick={this.saveArticle}
          size="large"
          className="mt-10 fr"
          type="primary"
        >
          Yadda saxla
          {this.state.loading ? <Spin /> : null}
        </Button>
      </div>
    );
  }
}

export default connect(null, { toggleLoading })(Article);
