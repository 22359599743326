import {
  SET_USER,
  SET_USER_ERROR,
  SET_USER_LOGGED_IN,
  LOADING_ON,
  LOADING_OFF,
  LOG_OUT,
} from "./../types";
import sualtap from "./../../const/api";
import history from "./../../const/history";

export const logUserIn = () => async (dispatch) => {
  dispatch({ type: LOADING_ON });
  await sualtap
    .get(`auth/me`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: SET_USER_LOGGED_IN,
        payload: {
          access_token: localStorage.getItem("access_token"),
          expires_in: 3600,
          token_type: "bearer",
          user: res.data,
        },
      });
      dispatch({
        type: LOADING_OFF,
      });
    })
    .catch((er) => {
      localStorage.removeItem("access_token");
      history.push("/");
      dispatch({
        type: LOADING_OFF,
      });
      logOut();
    })
    .finally(() => {
      toggleLoading(false);
    });
};

export const logInUser = (username, password) => async (dispatch) => {
  if (username.trim().length === 0 || password.trim().length === 0) {
    dispatch({
      type: SET_USER_ERROR,
      payload: { message: "Email və şifrə daxil edilməlidir" },
    });
  } else {
    toggleLoading(true);
    var obj = {
      username,
      password,
    };
    await sualtap
      .post(`auth/login`, JSON.stringify(obj))
      .then((res) => {
        localStorage.setItem("access_token", res.data.access_token);
        dispatch({
          type: SET_USER,
          payload: { data: res.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_USER_ERROR,
          payload: {
            message:
              error.response && error.response.status === 401
                ? "İstifadəçi adı və ya şifrə yanlışdır"
                : "Xəta baş verdi. Daha sonra yenidən cəhd edin.",
          },
        });
      })
      .finally(() => {
        toggleLoading(false);
      });
  }
};

export const loadingOn = () => ({ type: LOADING_ON });
export const loadingOff = () => ({ type: LOADING_OFF });
export const logOut = () => ({ type: LOG_OUT });
export const toggleLoading = (payload) => ({
  type: payload ? LOADING_ON : LOADING_OFF,
});
