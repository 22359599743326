import React from "react";
import { Table, Modal, Button, message, Popconfirm } from "antd";
import sualtap from "./../../const/api";

const columns = [
  {
    title: "Ad və soyad",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Mövzu",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Mesaj",
    dataIndex: "body",
    key: "body",
  },
  {
    title: "Əməliyyat",
    dataIndex: "operation",
    key: "operation",
  },
];

class Contacts extends React.Component {
  state = {
    contacts: [],
    visible: false,
    selected: 0,
    total: 10,
    page: 1,
  };

  fetchContacts = async (page) => {
    await sualtap
      .get(`contacts?per_page=10&page=${this.state.page}`)
      .then((res) => {
        this.setState({
          contacts: res.data.data,
          total: res.data.total,
        });
      });
  };

  componentDidMount() {
    this.fetchContacts();
  }

  showModal = (id) => {
    this.setState({
      visible: true,
      selected: id,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  deleteMessage = async (id) => {
    await sualtap.delete(`contacts/${id}`).then((res) => {
      message.success("Mesaj silindi!");
      this.fetchContacts();
    });
  };

  handlePageChange = (e) => {
    this.setState({ page: e.current }, () => {
      this.fetchContacts(e.current);
    });
  };

  render() {
    const dataSource = this.state.contacts.map((c) => ({
      key: c.id,
      ...c,
      name: c.name,
      operation: (
        <>
          <Button
            style={{ marginRight: "5px" }}
            type="primary"
            onClick={() => this.showModal(c.id)}
          >
            Bax
          </Button>
          <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this message?"
            onConfirm={() => this.deleteMessage(c.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Sil</Button>
          </Popconfirm>
        </>
      ),
    }));
    const message = this.state.contacts.find(
      (c) => c.id === this.state.selected
    );

    return (
      <>
        <div className="b-page-top">
          <h2>Mesajlar</h2>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{ pageSize: 10, total: this.state.total }}
          onChange={this.handlePageChange}
        />
        <Modal
          title="Mesaj"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleOk}
        >
          <div className="message-part">
            <span>Ad: </span>
            {message ? message.name : ""}
          </div>
          <div className="message-part">
            <span>Telefon: </span>
            {message ? message.phone : ""}
          </div>
          <div className="message-part">
            <span>Mesaj: </span>
            {message ? message.body : ""}
          </div>
        </Modal>
      </>
    );
  }
}

export default Contacts;
