import React from "react";
import loader from "./spinner.gif";
import {
  Input,
  Select,
  Button,
  Spin,
  message,
  Row,
  Col,
  Switch,
  Tabs,
  Modal,
  Popconfirm,
  Tooltip,
} from "antd";
import sualtap from "../../../const/api";
import Conjugation from "../Conjugation/Conjugation";
import { toggleLoading } from "./../../../redux/actions";
import {
  LoadingOutlined,
  SaveOutlined,
  DeleteOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import Synonyms from "./Tabs/Synonyms";
import Voice from "./Tabs/Voice";
import { connect } from "react-redux";
import history from "./../../../const/history";
import Words from "./Words";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 15, color: "white" }} spin />
);

const { TabPane } = Tabs;

const { Option } = Select;
class Word extends React.Component {
  state = {
    spin: false,
    loading: false,
    addWordVisible: false,
    sentenceModalVisible: false,
    sentence_keyword: "",
    sentence_for_phrase: false,
    sentence_for_index: null,
    selected_sentence: {},
    sentences: [],
    categories: [],
    types: [],
    word: {},
    openIndex: null,
    synonyms: [],
    synonymsFetched: false,
    conjugations: null,
    conjugationFetched: false,
  };

  handlePhraseChange = (e, index, key) => {
    let word = { ...this.state.word };
    word.phrases[index][key] = e.target.value;
    this.setState({ word });
  };

  handleSelectedSentence = (selected_sentence) => {
    this.setState({
      selected_sentence: this.state.sentences.find(
        (s) => s.id === selected_sentence
      ),
    });
  };

  toggleSentenceModal = (
    sentenceModalVisible,
    sentence_for_phrase,
    sentence_for_index
  ) => {
    this.setState({
      sentenceModalVisible,
      sentence_for_phrase,
      sentence_for_index,
    });
  };

  handleSentenceSearch = async (e) => {
    this.setState({ sentence_keyword: e }, async () => {
      await sualtap.get(`sentences?key_word=${e}&compact=1`).then((res) => {
        if (this.state.sentence_keyword === e) {
          this.setState({ sentences: res.data });
        }
      });
    });
  };

  handlePutSentence = async () => {
    if (this.state.sentence_for_phrase) {
      let word = { ...this.state.word };
      word.phrases[
        this.state.sentence_for_index
      ].sentence_id = this.state.selected_sentence.id;
      word.phrases[
        this.state.sentence_for_index
      ].sentence_az = this.state.selected_sentence.sentence_az;
      word.phrases[
        this.state.sentence_for_index
      ].sentence_es = this.state.selected_sentence.sentence_es;
      this.setState({ word, sentenceModalVisible: false });
    } else {
      await sualtap.post(
        `translations/${
          this.state.word.translatios[this.state.sentence_for_index].id
        }/sentence`,
        {
          sentences_ids: [this.state.selected_sentence.id],
          sentences: [],
        }
      );
      this.setState({ sentenceModalVisible: false }, () => {
        this.fetchWord();
      });
    }
  };

  deleteTranslation = async (index, id) => {
    const word = { ...this.state.word };
    word.translatios[index].loading = true;
    this.setState({ word });
    await sualtap.delete(`translations/${id}`).then((res) => {
      this.fetchWord();
    });
    word.translatios[index].loading = false;
    this.setState({ word });
  };

  saveTranslation = async (index, isnew) => {
    const translation = this.state.word.translatios[index];
    const word = { ...this.state.word };
    word.translatios[index].loading = true;
    this.setState({ word });
    let obj = {
      azerbaijani: translation.azerbaijani,
      spanish: translation.spanish,
      type_id: translation.type_id,
      category_id: translation.category_id,
      additional: translation.additional,
      additional_az: translation.additional_az,
      meaning_az:
        translation.meaning_az && translation.meaning_az.length
          ? translation.meaning_az
          : null,
      meaning_es:
        translation.meaning_es && translation.meaning_es.length
          ? translation.meaning_es
          : null,
      sentence_ids: [],
      sentences: [],
    };
    if (isnew) {
      await sualtap.post("translations", obj).then((res) => {
        this.fetchWord();
      });
    } else {
      delete obj.sentence_ids;
      delete obj.sentences;
      await sualtap.put(`translations/${translation.id}`, obj).then((res) => {
        this.fetchWord();
      });
    }
    word.translatios[index].loading = false;
    this.setState({ word });
  };

  savePhrase = async (index, isnew) => {
    const word = { ...this.state.word };
    const phrase = this.state.word.phrases[index];
    word.phrases[index].loading = true;
    this.setState({ word });
    let obj = {
      title_az: phrase.title_az,
      title_es: phrase.title_es,
      word_id: this.state.word.id,
      sentence_id: phrase.sentence_id,
      sentence_az: phrase.sentence_az,
      sentence_es: phrase.sentence_es,
    };
    if (isnew) {
      await sualtap.post("phrases", JSON.stringify(obj)).then((res) => {
        this.fetchWord();
      });
    } else {
      await sualtap
        .put(`phrases/${phrase.id}`, JSON.stringify(obj))
        .then((res) => {
          this.fetchWord();
        });
    }
    word.phrases[index].loading = false;
    this.setState({ word });
  };

  setPhraseSentenceOfOwn = (index) => {
    const word = { ...this.state.word };
    word.phrases[index].sentence_id = null;
    this.setState({ word });
  };

  handleSentencesAccordion = (index) => {
    const word = { ...this.state.word };
    word.translatios[index].opened = !this.state.word.translatios[index].opened;
    this.setState({
      word,
      openIndex: this.state.word.translatios[index].opened ? index : null,
    });
  };

  fetchWord = async () => {
    await sualtap.get(`words/${this.props.params.id}`).then((res) => {
      let translatios = [];
      let phrases = [];
      res.data.translatios.forEach((tr, index) => {
        const sentences = tr.sentences.map((s) => {
          return {
            sentence_az: s.sentence_az,
            sentence_es: s.sentence_es,
            id: s.id,
            delete: true,
            save: false,
            select: false,
            isnew: false,
          };
        });
        sentences.push({
          id: null,
          sentence_az: "",
          sentence_es: "",
          delete: false,
          save: false,
          select: true,
          isnew: true,
        });
        translatios.push({
          id: tr.id,
          spanish: tr.spanish?.id,
          azerbaijani: tr.azerbaijani?.id,
          meaning_az: tr.meaning_az,
          meaning_es: tr.meaning_es,
          additional: tr.additional !== "0",
          additional_az: tr.additional_az !== "0",
          loading: false,
          sentences,
          spanishWords: tr.spanish
            ? [{ id: tr.spanish.id, word: tr.spanish.word }]
            : [],
          azerbaijaniWords: tr.azerbaijani
            ? [{ id: tr.azerbaijani.id, word: tr.azerbaijani.word }]
            : [],
          type_id: +tr.type_id || null,
          category_id: +tr.category_id || null,
          categories: [...this.state.categories],
          types: [
            ...this.state.types.filter(
              (ty) => ty.category_id === tr.category_id
            ),
          ],
          opened: this.state.openIndex === index,
        });
      });
      translatios.push({
        id: null,
        spanish: res.data.type === "es" ? res.data.id : null,
        azerbaijani: res.data.type === "az" ? res.data.id : null,
        meaning_az: "",
        meaning_es: "",
        keyword: "",
        sentences: [{ id: null, sentence_az: "", sentence_es: "" }],
        spanishWords:
          res.data.type === "es"
            ? [{ id: res.data.id, word: res.data.word }]
            : [],
        azerbaijaniWords:
          res.data.type === "az"
            ? [{ id: res.data.id, word: res.data.word }]
            : [],
        type_id: null,
        category_id: null,
        categories: [...this.state.categories],
        types: [],
        loading: false,
        additional: true,
        additional_az: true,
        opened: false,
      });
      res.data.phrases.forEach((ph) => {
        phrases.push({
          title_es: ph.title_es,
          title_az: ph.title_az,
          sentence_id: ph.sentence_id,
          sentence_es: ph.sentence?.sentence_es,
          sentence_az: ph.sentence?.sentence_az,
          id: ph.id,
          loading: false,
        });
      });
      phrases.push({
        title_es: "",
        title_az: "",
        sentence_id: null,
        sentence_es: "",
        sentence_az: "",
        id: null,
        loading: false,
      });
      this.setState({
        word: { ...res.data, translatios, phrases },
        loading: false,
      });
    });
  };

  getCategories = async () => {
    await sualtap
      .get("categories", { params: { per_page: 10000 } })
      .then((res) => {
        this.setState({ categories: res.data.data });
      });
  };

  getTypes = async () => {
    await sualtap.get(`types`, { params: { per_page: 10000 } }).then((res) => {
      this.setState({ types: res.data.data });
    });
  };

  componentDidMount = async () => {
    this.props.toggleLoading(true);
    await this.getCategories();
    await this.getTypes();
    await this.fetchWord();
    this.props.toggleLoading(false);
  };

  handleWordChange = (e) => {
    const word = { ...this.state.word };
    word.word = e;
    this.setState({ word });
  };

  onTypeChange = (type) => {
    this.setState({ type });
  };

  editWord = async (e) => {
    e.preventDefault();
    if (!this.state.word.word.trim().length) {
      message.warning("Söz boş ola bilməz");
    } else {
      this.setState({ myloading: true });
      await sualtap
        .put(
          `words/${this.state.word.id}`,
          JSON.stringify({
            id: this.state.word.id,
            word: this.state.word.word,
            type: this.state.word.type,
          })
        )
        .then((res) => {
          message.success("Word edited");
          this.setState({ myloading: false });
        });
    }
  };

  setAddWordVisible = (addWordVisible) => {
    this.setState({ addWordVisible });
  };

  handleAdditionalChange = (index) => {
    const word = { ...this.state.word };
    word.translatios[index].additional = !this.state.word.translatios[index]
      .additional;
    this.setState({ word });
  };

  handleAdditionalAzChange = (index) => {
    const word = { ...this.state.word };
    word.translatios[index].additional_az = !this.state.word.translatios[index]
      .additional_az;
    this.setState({ word });
  };

  onTranslationChange = (e, key, index) => {
    const word = { ...this.state.word };
    word.translatios[index][key] = e;
    if (key === "category_id") {
      word.translatios[index].types = this.state.types.filter(
        (type) => +type.category_id === e
      );
    }
    this.setState({ word });
  };

  onSearchWord = async (keyword, key, type, index) => {
    if (keyword !== "") {
      this.setState({ keyword });
      await sualtap
        .get(`words?compact=1&type=${type}&word=${keyword}`)
        .then((res) => {
          const word = { ...this.state.word };
          word.translatios[index][key] = res.data;
          this.setState({ word });
        });
    }
  };

  deletePhrase = async (id, index) => {
    const word = { ...this.state.word };
    word.phrases[index].loading = true;
    this.setState({ word });
    await sualtap
      .delete(`phrases/${id}`)
      .then((res) => {
        this.fetchWord();
      })
      .finally(() => {
        word.phrases[index].loading = false;
        this.setState({ word });
      });
  };

  handleTranslationSentenceChange = (e, index, sentence, key) => {
    const word = { ...this.state.word };
    word.translatios[index].sentences[sentence][key] = e;
    this.setState({ word });
  };

  deleteTranslationSentence = async (isnew, id, index, sentence) => {
    if (!isnew) {
      await sualtap.delete(
        `translations/${this.state.word.translatios[index].id}/sentence/${id}`
      );
    } else {
      const word = { ...this.state.word };
      word.translatios[index].sentences.splice(sentence, 1);
      this.setState({ word });
    }
    this.fetchWord();
  };

  saveTranslationSentence = async (index, sentence) => {
    const { sentence_az, sentence_es } = this.state.word.translatios[
      index
    ].sentences[sentence];
    if (
      sentence_az === null ||
      sentence_es.length < 5 ||
      sentence_es === null ||
      sentence_az.length < 5
    ) {
      message.warn("Cümləni tam daxil edin.");
    } else {
      this.setState({ spin: true });
      let obj = {
        sentences_ids: [],
        sentences: [
          {
            az: sentence_az,
            es: sentence_es,
          },
        ],
      };
      await sualtap
        .post(
          `translations/${this.state.word.translatios[index].id}/sentence`,
          obj
        )
        .then((res) => {
          this.fetchWord();
          this.setState({ spin: false });
          message.success("Cümlə əlavə edildi");
        });
    }
  };

  deleteWord = async () => {
    this.setState({ myloading: true });
    await sualtap.delete(`words/${this.state.word.id}`).then((res) => {
      this.setState({ myloading: false });
      history.push("/words");
    });
  };

  render() {
    return !this.state.loading ? (
      <div className="word-container">
        <form onSubmit={this.editWord}>
          <Row gutter={[16, 24]}>
            <Col span={6}>
              <Input
                placeholder="Word"
                value={this.state.word.word}
                onChange={(e) => this.handleWordChange(e.target.value)}
                className="ant-input"
                type="text"
              />
            </Col>
            <Col span={2}>
              <Button htmlType="submit" type="primary">
                Edit word
                {this.state.myloading ? <Spin /> : null}
              </Button>
            </Col>
            <Col span={2}>
              <Popconfirm
                placement="topLeft"
                title="Are you sure to delete this word?"
                onConfirm={this.deleteWord}
                okText="Yes"
                cancelText="No"
              >
                <Button htmlType="submit" type="danger">
                  Delete word
                  {this.state.myloading ? <Spin /> : null}
                </Button>
              </Popconfirm>
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                onClick={() => this.setAddWordVisible(true)}
                className="pad-left"
              >
                New word
              </Button>
            </Col>
          </Row>
        </form>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Translations" key="1">
            {this.state.word.translatios
              ? this.state.word.translatios.map((tr, index) => {
                  return (
                    <div key={index} className="translation-box">
                      <Row className="mt-10 mb-10" key={index} gutter={[8, 8]}>
                        <Col span={4}>
                          <Select
                            className="fl w-100"
                            showSearch
                            placeholder="Select a category"
                            optionFilterProp="children"
                            value={tr.category_id}
                            onChange={(e) =>
                              this.onTranslationChange(e, "category_id", index)
                            }
                            allowClear
                          >
                            {tr.categories.map((t, index) => {
                              return (
                                <Option key={index} value={t.id}>
                                  {t.name_az} | {t.name_es}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        <Col span={4}>
                          <Select
                            className="fl w-100"
                            showSearch
                            placeholder="Select a type"
                            optionFilterProp="children"
                            value={tr.type_id}
                            onChange={(e) =>
                              this.onTranslationChange(e, "type_id", index)
                            }
                            allowClear
                          >
                            {tr.types.map((t, index) => {
                              return (
                                <Option key={index} value={t.id}>
                                  {t.name_az} | {t.name_es}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        {this.state.word.type !== "az" ? (
                          <Col span={6}>
                            <Select
                              className="fl w-100"
                              showSearch
                              placeholder="Select an azerbaijani word"
                              optionFilterProp="children"
                              value={tr.azerbaijani}
                              onChange={(e) =>
                                this.onTranslationChange(
                                  e,
                                  "azerbaijani",
                                  index
                                )
                              }
                              onSearch={(e) =>
                                this.onSearchWord(
                                  e,
                                  "azerbaijaniWords",
                                  "az",
                                  index
                                )
                              }
                            >
                              {tr.azerbaijaniWords?.map((t, index) => {
                                return (
                                  <Option key={index} value={t.id}>
                                    {t.word}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        ) : null}
                        {this.state.word.type !== "es" ? (
                          <Col span={6}>
                            <Select
                              className="fl w-100"
                              showSearch
                              placeholder="Select a spanish word"
                              optionFilterProp="children"
                              value={tr.spanish}
                              onChange={(e) =>
                                this.onTranslationChange(e, "spanish", index)
                              }
                              onSearch={(e) =>
                                this.onSearchWord(
                                  e,
                                  "spanishWords",
                                  "es",
                                  index
                                )
                              }
                            >
                              {tr.spanishWords?.map((t, index) => {
                                return (
                                  <Option key={index} value={t.id}>
                                    {t.word}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        ) : null}
                        <Col span={10}>
                          <Input
                            value={tr.meaning_az}
                            onChange={(e) =>
                              this.onTranslationChange(
                                e.target.value,
                                "meaning_az",
                                index
                              )
                            }
                            placeholder="Meaning az"
                          />
                        </Col>
                        <Col span={8}>
                          <Input
                            value={tr.meaning_es}
                            onChange={(e) =>
                              this.onTranslationChange(
                                e.target.value,
                                "meaning_es",
                                index
                              )
                            }
                            placeholder="Meaning es"
                          />
                        </Col>
                        <Col span={3} className="all-center">
                          <Switch
                            className="fr"
                            checkedChildren="Additional Es"
                            unCheckedChildren="Main Es"
                            checked={tr.additional}
                            onChange={(e) => this.handleAdditionalChange(index)}
                          ></Switch>
                        </Col>
                        <Col span={3} className="all-center">
                          <Switch
                            className="fr"
                            checkedChildren="Additional Az"
                            unCheckedChildren="Main Az"
                            checked={tr.additional_az}
                            onChange={(e) =>
                              this.handleAdditionalAzChange(index)
                            }
                          ></Switch>
                        </Col>
                        <Col span={4}>
                          <Button
                            type="primary"
                            className="w-100 disabled-btn"
                            disabled={tr.loading}
                            onClick={() =>
                              this.saveTranslation(index, tr.id === null)
                            }
                          >
                            {tr.id === null
                              ? "Yeni tərcümə yarat"
                              : "Düzəliş et"}
                            {tr.loading ? <Spin indicator={antIcon} /> : null}
                          </Button>
                        </Col>
                        {tr.id !== null ? (
                          <Col span={4}>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.handleSentencesAccordion(index)
                              }
                              className="w-100 disabled-btn"
                            >
                              {tr.opened ? "Cümlələri bağla" : "Cümlələrə bax"}
                            </Button>
                          </Col>
                        ) : null}
                        <Col span={2}>
                          <Popconfirm
                            placement="topRight"
                            title={"Tərcüməni silmək istədiyinizdən əminsiniz?"}
                            onConfirm={() =>
                              this.deleteTranslation(index, tr.id)
                            }
                            okText="Bəli"
                            cancelText="Xeyr"
                          >
                            <Button
                              type="primary"
                              className="w-100 disabled-btn"
                              disabled={tr.loading}
                            >
                              Sil{" "}
                              {tr.loading ? <Spin indicator={antIcon} /> : null}
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                      <div
                        className={`accordion-tab ${
                          tr.opened ? "opened" : "closed"
                        }`}
                      >
                        <h3 className="bold">Cümlələr</h3>
                        {tr.sentences.map((s, sentence_index) => {
                          return (
                            <Row key={sentence_index} gutter={[8, 8]}>
                              <Col span={9}>
                                <Input
                                  value={s.sentence_az}
                                  onChange={(e) =>
                                    this.handleTranslationSentenceChange(
                                      e.target.value,
                                      index,
                                      sentence_index,
                                      "sentence_az"
                                    )
                                  }
                                  disabled={s.id !== null}
                                  placeholder="Sentence az"
                                />
                              </Col>
                              <Col span={9}>
                                <Input
                                  value={s.sentence_es}
                                  onChange={(e) =>
                                    this.handleTranslationSentenceChange(
                                      e.target.value,
                                      index,
                                      sentence_index,
                                      "sentence_es"
                                    )
                                  }
                                  disabled={s.id !== null}
                                  placeholder="Sentence es"
                                />
                              </Col>
                              {s.select ? (
                                <Col span={2}>
                                  <Tooltip
                                    placement="topLeft"
                                    title="Cümlə seç"
                                  >
                                    <Button
                                      type="primary"
                                      className="w-100"
                                      onClick={() =>
                                        this.toggleSentenceModal(
                                          true,
                                          false,
                                          index
                                        )
                                      }
                                    >
                                      <SelectOutlined />
                                    </Button>
                                  </Tooltip>
                                </Col>
                              ) : null}
                              {s.delete ? (
                                <Col span={2}>
                                  <Tooltip placement="topLeft" title="Sil">
                                    <Popconfirm
                                      placement="topRight"
                                      title={
                                        "Cümləni silmək istədiyinizdən əminsiniz?"
                                      }
                                      onConfirm={() =>
                                        this.deleteTranslationSentence(
                                          s.isnew,
                                          s.id,
                                          index,
                                          sentence_index
                                        )
                                      }
                                      okText="Bəli"
                                      cancelText="Xeyr"
                                    >
                                      <Button type="primary" className="w-100">
                                        <DeleteOutlined />
                                      </Button>
                                    </Popconfirm>
                                  </Tooltip>
                                </Col>
                              ) : null}
                              {s.save || s.id === null ? (
                                <Col span={2}>
                                  <Tooltip
                                    placement="topLeft"
                                    title="Yadda saxla"
                                    onClick={() =>
                                      this.saveTranslationSentence(
                                        index,
                                        sentence_index
                                      )
                                    }
                                  >
                                    <Button type="primary" className="w-100">
                                      <SaveOutlined />
                                    </Button>
                                  </Tooltip>
                                </Col>
                              ) : null}
                            </Row>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              : null}
          </TabPane>
          <TabPane tab="Phrases" key="2">
            {this.state.word.phrases
              ? this.state.word.phrases.map((tr, index) => {
                  return (
                    <Row className="mt-10 mb-10" key={index} gutter={[8, 8]}>
                      <Col span={12}>
                        <Input
                          value={tr.title_az}
                          onChange={(e) =>
                            this.handlePhraseChange(e, index, "title_az")
                          }
                          placeholder="Title az"
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          value={tr.title_es}
                          onChange={(e) =>
                            this.handlePhraseChange(e, index, "title_es")
                          }
                          placeholder="Title es"
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          value={tr.sentence_az}
                          disabled={tr.sentence_id !== null}
                          onChange={(e) =>
                            this.handlePhraseChange(e, index, "sentence_az")
                          }
                          placeholder="Sentence az"
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          value={tr.sentence_es}
                          disabled={tr.sentence_id !== null}
                          onChange={(e) =>
                            this.handlePhraseChange(e, index, "sentence_es")
                          }
                          placeholder="Sentence es"
                        />
                      </Col>

                      <Col span={4}>
                        <Button
                          type="primary"
                          className="w-100"
                          onClick={() =>
                            this.toggleSentenceModal(true, true, index)
                          }
                        >
                          Cümlə seç
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          className="w-100"
                          onClick={() => this.setPhraseSentenceOfOwn(index)}
                        >
                          Cümləni yaz
                        </Button>
                      </Col>
                      {tr.id !== null ? (
                        <Col span={4}>
                          <Popconfirm
                            placement="topRight"
                            title={"İfadəni silmək istədiyinizdən əminsiniz?"}
                            onConfirm={() => this.deletePhrase(tr.id, index)}
                            okText="Bəli"
                            cancelText="Xeyr"
                          >
                            <Button
                              type="primary"
                              className="w-100 disabled-btn"
                              disabled={tr.loading}
                            >
                              Sil{" "}
                              {tr.loading ? <Spin indicator={antIcon} /> : null}
                            </Button>
                          </Popconfirm>
                        </Col>
                      ) : null}
                      <Col span={4}>
                        <Button
                          type="primary"
                          disabled={tr.loading}
                          onClick={() => this.savePhrase(index, tr.id === null)}
                          className="w-100 disabled-btn"
                        >
                          Yadda saxla
                          {tr.loading ? <Spin indicator={antIcon} /> : null}
                        </Button>
                      </Col>
                    </Row>
                  );
                })
              : null}
          </TabPane>
          <TabPane tab="Synonyms" key="3">
            <Synonyms fetchWord={this.fetchWord} id={this.props.params.id} />
          </TabPane>
          <TabPane tab="Conjugation" key="4">
            <Conjugation
              fetchWord={this.fetchWord}
              word_id={this.props.params.id}
            />
          </TabPane>
          <TabPane tab="Voices" key="5">
            <Voice
              fetchWord={this.fetchWord}
              id={this.state.word.id}
              voices={this.state.word.voices}
            />
          </TabPane>
        </Tabs>
        <Modal
          title="Cümlə seç"
          visible={this.state.sentenceModalVisible}
          onOk={this.handlePutSentence}
          onCancel={() => this.toggleSentenceModal(false)}
        >
          <Select
            showSearch={true}
            value={this.state.selected_sentence.id}
            showArrow={false}
            optionFilterProp="children"
            placeholder="Cümlə axtar"
            notFoundContent={null}
            onSearch={(e) => this.handleSentenceSearch(e)}
            onChange={this.handleSelectedSentence}
            className="w-100 mb-10"
          >
            {this.state.sentences.map((d) => (
              <Option key={d.id} value={d.id}>
                {d.sentence_az} | {d.sentence_es}
              </Option>
            ))}
          </Select>
        </Modal>
        <Modal
          footer={null}
          title="Söz əlavə et"
          visible={this.state.addWordVisible}
          onCancel={() => this.setAddWordVisible(false)}
        >
          <Words modal={true} />
        </Modal>
        {this.state.spin ? (
          <img src={loader} alt="_loader" className="bottomRightSpinner" />
        ) : null}
      </div>
    ) : null;
  }
}

export default connect(null, { toggleLoading })(Word);
