import React from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { toggleLoading } from "../../../redux/actions";
import { connect } from "react-redux";
import sualtap from "../../../const/api";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (i) => <img className="table-img" src={i} alt="_img" />,
  },
  {
    title: "Level",
    dataIndex: "level",
    key: "level",
    render: (l) => <p>{l.name}</p>,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Preview",
    dataIndex: "preview",
    key: "preview",
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Texts extends React.Component {
  state = {
    texts: [],
    total: 10,
    page: 1,
    per_page: 5,
  };

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchTexts();
    });
  };

  deleteText = async (id) => {
    await sualtap
      .delete(`texts/${id}`)
      .then((res) => {
        message.success("Xidmət uğurla silindi");
        this.fetchTexts();
      })
      .catch((err) => {
        message.success("Xəta baş verdi. Xidmətin silinməsi mümkün olmadı.");
      });
  };

  fetchTexts = async () => {
    const { page, per_page } = this.state;
    this.props.toggleLoading(true);
    await sualtap.get(`texts?page=${page}&per_page=${per_page}`).then((res) => {
      this.setState({
        texts: res.data.data.map((s, index) => {
          return {
            key: index,
            ...s,
            buttons: (
              <>
                <Button type="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ispanadicto.az/texts/${s.id}`}
                  >
                    Open
                  </a>
                </Button>
                <Button className="ml-5">
                  <Link to={`/texts/edit/${s.id}`}>Edit</Link>
                </Button>
                <Popconfirm
                  title="Are you sure delete this service?"
                  onConfirm={() => this.deleteText(s.id)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="ml-5" danger>
                    Delete
                  </Button>
                </Popconfirm>
              </>
            ),
          };
        }),
        total: res.data.total
      });
    });
    this.props.toggleLoading(false);
  };

  componentDidMount() {
    this.fetchTexts();
  }

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Texts</h2>
          <Button type="primary">
            <Link to="/texts/new">Text əlavə et</Link>
          </Button>
        </div>
        <div className="floater">
          <Table
            dataSource={this.state.texts}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </div>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Texts);