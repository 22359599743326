import React from "react";
import { Input, Row, Col, Button, message, Spin } from "antd";
import sualtap from "../../../const/api";
import { loadingOn, loadingOff } from "./../../../redux/actions";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class Static extends React.Component {
  state = { info: {}, loading: false };

  componentDidMount = async () => {
    this.props.loadingOn();
    await sualtap.get("info/all").then((res) => {
      this.setState(
        {
          info: res.data,
        },
        () => {
          this.props.loadingOff();
        }
      );
    });
  };

  changeValue = (e, type) => {
    let info = { ...this.state.info };
    info[type] = e.target.value;
    this.setState({ info });
  };

  changeEditorValue = (e, type) => {
    let info = { ...this.state.info };
    info[type] = e;
    this.setState({ info });
  };

  changeInfos = async () => {
    this.setState({ loading: true });
    await sualtap.put("info", { ...this.state.info }).then((res) => {
      this.setState({ loading: false }, () => {
        message.success("Düzəliş edildi");
      });
    });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Static information</h2>
        </div>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <CKEditor
              id="azEditor"
              editor={ClassicEditor}
              data={this.state.info.about_us_az}
              onChange={(event, editor) => {
                this.changeEditorValue(editor.getData(), "about_us_az");
              }}
            />
          </Col>
          <Col span={24}>
            <CKEditor
              id="azEditor"
              editor={ClassicEditor}
              data={this.state.info.about_us_es}
              onChange={(event, editor) => {
                this.changeEditorValue(editor.getData(), "about_us_es");
              }}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Facebook"
              value={this.state.info.facebook}
              onChange={(e) => this.changeValue(e, "facebook")}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Instagram"
              value={this.state.info.instagram}
              onChange={(e) => this.changeValue(e, "instagram")}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Linkedin"
              value={this.state.info.linkedIn}
              onChange={(e) => this.changeValue(e, "linkedIn")}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Twitter"
              value={this.state.info.twitter}
              onChange={(e) => this.changeValue(e, "twitter")}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Youtube"
              value={this.state.info.youtube}
              onChange={(e) => this.changeValue(e, "youtube")}
            />
          </Col>
        </Row>
        <Button className="mt-10 fr" type="primary" onClick={this.changeInfos}>
          Yadda saxla
          {this.state.loading ? <Spin /> : null}
        </Button>
      </>
    );
  }
}

export default connect(null, { loadingOn, loadingOff })(Static);
