import React from "react";
import { Table, Button, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import { toggleLoading } from "./../../../redux/actions";
import { connect } from "react-redux";
import sualtap from "../../../const/api";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (i) => <img className="table-img" src={i.url} alt="_img" />,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Operations",
    dataIndex: "buttons",
    key: "buttons",
  },
];

class Articles extends React.Component {
  state = {
    articles: [],
    total: 10,
    per_page: 5,
    page: 1,
  };

  fetchArticles = async () => {
    const { page, per_page } = this.state;
    this.props.toggleLoading(true);
    await sualtap.get(`blog?page=${page}&per_page=${per_page}`).then((res) => {
      console.log(res);
      this.setState({
        total: res.data.total,
        page: res.data.page,
        per_page: res.data.per_page,
        articles: res.data.data.map((s, index) => {
          return {
            key: index,
            ...s,
            buttons: (
              <>
                <Button type="primary">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ispanadicto.az/articles/${s.id}`}
                  >
                    Open
                  </a>
                </Button>
                <Button className="ml-5">
                  <Link to={`/articles/edit/${s.id}`}>Edit</Link>
                </Button>
                <Popconfirm
                  title="Are you sure delete this service?"
                  onConfirm={() => this.deleteArticle(s.id)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className="ml-5" danger>
                    Delete
                  </Button>
                </Popconfirm>
              </>
            ),
          };
        }),
      });
    });
    this.props.toggleLoading(false);
  };

  deleteArticle = async (id) => {
    await sualtap
      .delete(`blog/${id}`)
      .then((res) => {
        message.success("Məqalə uğurla silindi");
        this.fetchArticles();
      })
      .catch((err) => {
        message.success("Xəta baş verdi. Məqalənin silinməsi mümkün olmadı.");
      });
  };

  componentDidMount() {
    this.fetchArticles();
  }

  changePage = (e) => {
    this.setState({ page: e.current, per_page: e.pageSize }, () => {
      this.fetchArticles();
    });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Articles</h2>
          <Button type="primary">
            <Link to="/articles/new">Məqalə əlavə et</Link>
          </Button>
        </div>
        <div className="floater">
          <Table
            dataSource={this.state.articles}
            columns={columns}
            pagination={{
              pageSize: this.state.per_page,
              total: this.state.total,
              current_page: this.state.page,
            }}
            onChange={this.changePage}
          />
        </div>
      </>
    );
  }
}

export default connect(null, { toggleLoading })(Articles);
