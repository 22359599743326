import React, { useEffect, useState } from "react";
import { Row, Col, Button, Input, Select, message, Popconfirm } from "antd";
import sualtap from "./../../../../const/api";
const { Option } = Select;

const Synonyms = (props) => {
  let [trigger, setTrigger] = useState(0);

  const getEmptyGroup = () => {
    return {
      title_az: "",
      title_es: "",
      id: null,
      prevs: [],
      words: [],
      searchWords: [],
    };
  };
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const getSynonyms = async () => {
      await sualtap.get("synonyms?word_id=" + props.id).then((res) => {
        console.log(res.data);
        const all = [];
        res.data.forEach((element) => {
          all.unshift({
            title_az: element.title_az,
            title_es: element.title_es,
            id: element.id,
            prevs: element.words
              .map((w) => {
                return {
                  id: w.word_id,
                  synonym_id: w.id,
                };
              })
              .filter((w) => w.id !== props.id.toString()),
            words: element.words
              .map((w) => w.word_id)
              .filter((w) => w !== props.id.toString()),
            searchWords: element.words
              .filter((w) => w.word_id !== props.id.toString())
              .map((w) => w.word),
          });
        });
        all.push(getEmptyGroup());
        setGroups(all);
      });
    };

    getSynonyms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, trigger]);

  const handleWordSearch = async (e, index) => {
    await sualtap.get(`words?per_page=100&word=${e}`).then((res) => {
      const all = [...groups];
      all[index].searchWords = res.data.data;
      setGroups(all);
    });
  };

  const handleSelectedWords = (e, index) => {
    const all = [...groups];
    all[index].words = e;
    setGroups(all);
  };

  const handleTitleChange = (e, key, index) => {
    const all = [...groups];
    all[index][key] = e.target.value;
    setGroups(all);
  };

  const saveSynonymGroup = async (index) => {
    if (
      groups[index].title_az.length &&
      groups[index].title_es.length &&
      groups[index].words.length
    ) {
      const group = groups[index];
      const words = [...group.words];
      words.push(props.id.toString());
      const obj = {
        title_az: group.title_az,
        title_es: group.title_es,
        words: words,
      };
      if (group.id) {
        const todelete = [];
        const toadd = [];
        group.prevs.forEach((p) => {
          if (!group.words.includes(p.id)) {
            todelete.push(p.synonym_id);
          }
        });
        group.words.forEach((w) => {
          if (!group.prevs.map(p=>p.id).includes(w)) {
            toadd.push(w);
          }
        });
        if (toadd.length) {
          await sualtap
            .post(`synonyms/${group.id}/add`, { words: toadd })
            .then((res) => {});
        }
        if (todelete.length) {
          await sualtap
            .post(`synonyms/${group.id}/remove`, { synonyms: todelete })
            .then((res) => {});
        }
        await sualtap
          .put(`synonyms/${group.id}`, {
            title_az: group.title_az,
            title_es: group.title_es,
          })
          .then((res) => {
            message.success("Düzəliş edildi");
            setTrigger(++trigger);
          });
      } else {
        await sualtap.post("synonyms", obj).then((res) => {
          message.success("Sinonim qrupu uğurla yaradıldı");
          setTrigger(++trigger);
        });
      }
    } else {
      message.warning("Başlıq daxil edilməli və ən az bir söz seçilməlidir.");
    }
  };

  const deleteSynonymGroup = async (index) => {
    await sualtap.delete(`synonyms/${groups[index].id}`).then((res) => {
      setTrigger(++trigger);
    });
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        {groups.map((g, index) => {
          return (
            <Col key={index} span={24}>
              <Row key={index} gutter={[10, 10]}>
                <Col span={12}>
                  <Input
                    value={g.title_es}
                    onChange={(e) => handleTitleChange(e, "title_es", index)}
                    placeholder="Spanish title"
                  />
                </Col>
                <Col span={12}>
                  <Input
                    value={g.title_az}
                    onChange={(e) => handleTitleChange(e, "title_az", index)}
                    placeholder="Azərbaycan dilində başlıq"
                  />
                </Col>
                <Col span={24}>
                  <Select
                    showSearch={true}
                    showArrow={false}
                    value={g.words}
                    optionFilterProp="children"
                    placeholder="Söz axtar"
                    mode="tags"
                    notFoundContent={null}
                    onSearch={(e) => handleWordSearch(e, index)}
                    onChange={(e) => handleSelectedWords(e, index)}
                    className="w-100 mb-10"
                  >
                    {g.searchWords.map((d) => (
                      <Option key={d.id} value={d.id.toString()}>
                        {d.word}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Button type="primary" onClick={() => saveSynonymGroup(index)}>
                  {g.id ? "Yadda saxla" : "Yeni qrup yarat"}
                </Button>
                {g.id ? (
                  <Popconfirm
                    placement="topLeft"
                    title="Are you sure?"
                    onConfirm={() => deleteSynonymGroup(index)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" className="ml-10">
                      Qrupu sil
                    </Button>
                  </Popconfirm>
                ) : null}
              </Row>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default Synonyms;
