import React, { useState, useEffect } from "react";
import { Upload } from "antd";

const Uploader = (props) => {
  const [fileList, setFileList] = useState(props.prevImg);

  useEffect(() => {
    setFileList(props.prevImg);
  }, [props.prevImg]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    console.log(newFileList);
    props.setFile(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Upload
      customRequest={dummyRequest}
      listType="picture-card"
      fileList={fileList}
      onChange={onChange}
      onPreview={onPreview}
      multiple={props.multiple}
    >
      {fileList.length < props.count && "+ Upload"}
    </Upload>
  );
};

export default Uploader;
