import axios from "axios";
import siteUrl from "./const";
import { message } from "antd";

export const sualtap = axios.create({
  baseURL: `${siteUrl}api`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

sualtap.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = localStorage.getItem("access_token")
      ? "Bearer " + localStorage.getItem("access_token")
      : null;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//

sualtap.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 403) {
        localStorage.removeItem("access_token");
        window.location.reload();
      } else {
        message.warn("Xəta baş verdi.");
      }
      return Promise.reject(error);
    }
  }
);

export default sualtap;
