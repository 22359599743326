import React from "react";
import { Row, Col, Table, Spin, Button, message } from "antd";
import sualtap from "./../../../const/api";
import Uploader from "./Uploader";
import { FacebookOutlined, LinkedinOutlined } from "@ant-design/icons";
import { toggleLoading } from "./../../../redux/actions";
import { connect } from "react-redux";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "first_name",
  },
  {
    title: "Surname",
    dataIndex: "last_name",
  },
  {
    title: "Description - az",
    dataIndex: "description_az",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
  {
    title: "Social",
    dataIndex: "social",
  },
  {
    title: "Düzəliş",
    render: (a) => a.buttons,
  },
];

class Users extends React.Component {
  state = {
    user: this.getEmptyUser(),
    tableData: [],
    users: [],
    editingId: null,
    loading: false,
    visible: false,
    selected: null,
  };

  getEmptyUser() {
    return {
      first_name: "",
      last_name: "",
      facebook: "",
      linkedIn: "",
      description_az: "",
      description_es: "",
      phone: "",
      email: "",
      image: null,
    };
  }

  cancelEdit = () => {
    this.setState({
      editingId: null,
      loading: false,
      user: this.getEmptyUser(),
    });
  };

  loadingOn() {
    this.setState({ loading: true });
  }

  editSentence = (id) => {
    this.setState({
      editingId: id,
      user: { ...this.state.users.find((u) => u.id === id) },
    });
  };

  fetchUsers = async () => {
    await sualtap.get("users").then((res) => {
      this.setState({
        users: [...res.data],
        tableData: res.data.map((re) => {
          return {
            key: re.id,
            ...re,
            social: (
              <>
                <a target="_blank" rel="noopener noreferrer" href={re.facebook}>
                  <FacebookOutlined
                    style={{ fontSize: "25px", cursor: "pointer" }}
                  />
                </a>
                <a target="_blank" rel="noopener noreferrer" href={re.linkedIn}>
                  <LinkedinOutlined
                    style={{
                      fontSize: "25px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </>
            ),
            buttons: (
              <>
                <Button
                  onClick={() => this.editSentence(re.id)}
                  className="ml-5"
                >
                  Edit
                </Button>
              </>
            ),
          };
        }),
      });
    });
  }

  async componentDidMount() {
    this.props.toggleLoading(true);
    await this.fetchUsers();
    this.props.toggleLoading(false);
  }

  isNullOrEmpty(str) {
    return !str.length || str == null;
  }

  setUserPhoto = (image) => {
    let user = { ...this.state.user };
    user.image = image;
    this.setState({ user });
  };

  validateUser() {
    const {
      first_name,
      last_name,
      facebook,
      linkedIn,
      phone,
      email,
      description_es,
      description_az,
    } = this.state.user;
    if (
      this.isNullOrEmpty(first_name) ||
      this.isNullOrEmpty(last_name) ||
      this.isNullOrEmpty(facebook) ||
      this.isNullOrEmpty(linkedIn) ||
      this.isNullOrEmpty(phone) ||
      this.isNullOrEmpty(email) ||
      this.isNullOrEmpty(description_az) ||
      this.isNullOrEmpty(description_es)
    ) {
      return false;
    }
    return true;
  }

  editUser = async (e) => {
    e.preventDefault();
    if (this.validateUser()) {
      this.loadingOn();
      let data = new FormData();
      data.append("first_name", this.state.user.first_name);
      data.append("last_name", this.state.user.last_name);
      data.append("facebook", this.state.user.facebook);
      data.append("linkedIn", this.state.user.linkedIn);
      data.append("phone", this.state.user.phone);
      data.append("email", this.state.user.email);
      data.append("description_az", this.state.user.description_az);
      data.append("description_es", this.state.user.description_es);
      data.append("image", this.state.user.image);
      await sualtap.post(`users/${this.state.editingId}`, data).then((res) => {
        message.success("User edited");
        this.fetchUsers();
        this.cancelEdit();
      });
    } else {
      message.warning("User data cannot be empty");
    }
  };

  handleTextChanged = (e, type) => {
    let user = { ...this.state.user };
    user[type] = e.target.value;
    this.setState({ user });
  };

  render() {
    return (
      <>
        <div className="b-page-top">
          <h2>Users</h2>
        </div>
        <div className="w-100">
          {this.state.editingId ? (
            <div className="b-add-box-right w-100">
              <form onSubmit={this.editUser}>
                <Row gutter={16}>
                  <Col span={6}>
                    <input
                      placeholder="Name"
                      value={this.state.user.first_name}
                      onChange={(e) => this.handleTextChanged(e, "first_name")}
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6}>
                    <input
                      placeholder="Surname"
                      value={this.state.user.last_name}
                      onChange={(e) => this.handleTextChanged(e, "last_name")}
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6}>
                    <input
                      placeholder="Phone"
                      value={this.state.user.phone}
                      onChange={(e) => this.handleTextChanged(e, "phone")}
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6}>
                    <input
                      placeholder="Email"
                      value={this.state.user.email}
                      onChange={(e) => this.handleTextChanged(e, "email")}
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6} className="mt-10">
                    <input
                      placeholder="Facebook"
                      value={this.state.user.facebook}
                      onChange={(e) => this.handleTextChanged(e, "facebook")}
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6} className="mt-10">
                    <input
                      placeholder="LinkedIn"
                      value={this.state.user.linkedIn}
                      onChange={(e) => this.handleTextChanged(e, "linkedIn")}
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6} className="mt-10">
                    <input
                      placeholder="Title - Az"
                      value={this.state.user.description_az}
                      onChange={(e) =>
                        this.handleTextChanged(e, "description_az")
                      }
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                  <Col span={6} className="mt-10">
                    <input
                      placeholder="Title - Es"
                      value={this.state.user.description_es}
                      onChange={(e) =>
                        this.handleTextChanged(e, "description_es")
                      }
                      className="ant-input"
                      type="text"
                    />
                  </Col>
                </Row>
                <span className="mt-10">
                  <Uploader setPhoto={this.setUserPhoto} />
                </span>

                <Button htmlType="submit" type="primary">
                  Edit User
                  {this.state.loading ? <Spin /> : null}
                </Button>
                {this.state.editingId !== null ? (
                  <Button
                    onClick={this.cancelEdit}
                    type="primary"
                    className="ml-5"
                  >
                    Cancel edit
                  </Button>
                ) : null}
              </form>
            </div>
          ) : null}
          <div className="b-add-box-left w-100 mt-10">
            <Table
              columns={columns}
              dataSource={this.state.tableData}
              size="middle"
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, {toggleLoading})(Users);